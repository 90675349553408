/* eslint-disable max-lines */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { GoRepoTemplate } from 'react-icons/go';
import { HiOutlinePlusSm, HiOutlineTrash } from 'react-icons/hi';
import { MdOutlineClose } from 'react-icons/md';
import InfiniteScroll from 'react-infinite-scroll-component';

import { IPaginationResponse } from '@/@types';
import { INewMesssageTemplate, ITemplate } from '@/@types/chat';
import CircularLoader from '@/components/ClipLoader';
import ConfirmationModal from '@/components/ConfirmationModal';
import FormField from '@/components/FormField';
import SearchBar from '@/components/SearchBar';
import StateIndicator from '@/components/StateIndicator';
import TooltipComponent from '@/components/Tooltip';
import { Typography } from '@/components/Typography';
import { Button } from '@/components/ui/button';
import { Textarea } from '@/components/ui/textarea';
import useDebounce from '@/hooks/useDebounce';
import { useGenericMutation } from '@/hooks/useMutationData';
import { cn } from '@/lib/utils';
import { strings } from '@/locales';
import { useAuth } from '@/provider/AuthProvider';
import { createTemplate, deleteTemplate, getTemplates } from '@/services/chat';
import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';

interface ITemplateSection {
  setMessage: React.Dispatch<React.SetStateAction<string>>;
}

const TemplateSection: React.FC<ITemplateSection> = ({ setMessage }) => {
  const templateBoxRef = useRef<HTMLDivElement>(null);
  const templateSidebar = useRef<HTMLDivElement>(null);
  const { chatScreen: staticText, common } = strings;

  const { user } = useAuth();
  const queryClient = useQueryClient();

  const [openTemplateBox, setOpenTemplateBox] = useState(false);
  const [sidebarOpen, setSideBarOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [idForDelete, setIdForDelete] = useState<{
    id: string;
    name: string;
  } | null>(null);

  const debouncedSearchText = useDebounce(searchText);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isSubmitted, isDirty },
  } = useForm<INewMesssageTemplate>({
    mode: 'all',
    defaultValues: {
      title: '',
      content: '',
    },
  });

  const { data, fetchNextPage, hasNextPage, isLoading } = useInfiniteQuery({
    queryKey: ['getTemplates', debouncedSearchText],
    queryFn: ({ pageParam = 1 }) =>
      getTemplates(pageParam.toString(), debouncedSearchText),
    initialPageParam: 1,
    enabled: !!openTemplateBox,
    getNextPageParam: (data, pages) => {
      if (typeof data === 'object' && pages.length < data.totalPages) {
        return pages.length + 1;
      }
      return undefined;
    },
  });

  const templates = useMemo(() => {
    return (
      data?.pages
        .flatMap((page) => (page as IPaginationResponse<ITemplate>).results)
        .filter(Boolean) || []
    );
  }, [data?.pages]);

  const handleSelectTemplate = (text: string) => {
    setMessage(text);
    setOpenTemplateBox(false);
  };

  const handleSidebar = () => {
    reset();
    setSideBarOpen(!sidebarOpen);
    setOpenTemplateBox(false);
  };

  const createTemplateMutation = useGenericMutation<
    INewMesssageTemplate,
    INewMesssageTemplate | boolean
  >((payload) => createTemplate(payload), {
    onSuccess: (response) => {
      if (response) {
        reset();
        queryClient.invalidateQueries({
          queryKey: ['getTemplates'],
        });
        setSideBarOpen(!sidebarOpen);
      }
    },
  });

  const handleCreateTemplateSubmit: SubmitHandler<INewMesssageTemplate> = (
    formData,
  ) => {
    createTemplateMutation.mutate(formData);
  };

  const deleteTemplateMutation = useGenericMutation<string, boolean>(
    deleteTemplate,
    {
      onSuccess: (response, id) => {
        if (response) {
          setIdForDelete({ id: '', name: '' });
          queryClient.setQueryData<{
            pages: IPaginationResponse<ITemplate>[];
            pageParams: number[];
          }>(['getTemplates'], (oldData) => {
            if (!oldData) return oldData;
            return {
              ...oldData,
              pages: oldData.pages.map((page) => ({
                ...page,
                results: page.results.filter((template) => template._id !== id),
              })),
            };
          });
        }
      },
    },
  );

  const handleDeleteModal = () => {
    if (idForDelete) {
      deleteTemplateMutation.mutate(idForDelete.id);
    }
  };

  const adjustTextareaHeight = (textarea: HTMLTextAreaElement) => {
    textarea.style.height = '10rem';
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        templateBoxRef.current &&
        !templateBoxRef.current.contains(event.target as Node)
      ) {
        setOpenTemplateBox(false);
      }
      if (
        templateSidebar.current &&
        !templateSidebar.current.contains(event.target as Node) &&
        !createTemplateMutation.isPending
      ) {
        setSideBarOpen(false);
        reset();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const Content = () => {
    if (isLoading)
      return (
        <div className='flex items-center justify-center h-48'>
          <StateIndicator state='Loading' />
        </div>
      );
    if (templates.length <= 0)
      return (
        <div className='flex items-center justify-center h-48'>
          <StateIndicator state='Empty' />
        </div>
      );
    return templates.map((item, index) => (
      <TooltipComponent
        key={item._id}
        align='center'
        directionalArrow
        trigger={
          <div
            onClick={() => handleSelectTemplate(item.content)}
            className='w-full flex justify-between items-center group/template cursor-pointer py-1.5 min-h-9 px-2 bg-flashWhite rounded-md
                     transition-all duration-300 hover:bg-primary'
          >
            <Typography
              className='block md:text-sm w-full
                                transition-all duration-300 group-hover/template:text-white'
            >
              {index < 9 ? '0' : ''}
              {index + 1}: {item?.title}
            </Typography>
            {user?._id === item.author._id ? (
              <div className='group/button'>
                <Button
                  className='p-1 md:!w-fit h-fit group-hover/button:bg-red-500 group/button cursor-pointer'
                  variant='ghost'
                  onClick={(e) => {
                    e.stopPropagation();
                    setIdForDelete({ id: item._id, name: item.title });
                  }}
                >
                  <HiOutlineTrash className='text-tomatoRed text-base transition-all group-hover/button:text-white' />
                </Button>
              </div>
            ) : null}
          </div>
        }
      >
        <div className='capitalize rounded-lg w-60 max-h-40 overflow-scroll'>
          <Typography className='text-xs md:text-sm text-mo text-greyishBlack'>
            {item.content}
          </Typography>
        </div>
      </TooltipComponent>
    ));
  };

  return (
    <>
      {/* sidebar */}
      <div
        className={cn(
          'border-l px-5 absolute top-0 h-full z-50 right-[-25vw] w-[25vw] bg-white transition-[right] duration-300 ease-in-out flex flex-col',
          {
            'right-0 w-[25vw]': sidebarOpen && window.innerWidth >= 999, // Large screens (≥999px)
            'right-[-25vw] w-[25vw]': !sidebarOpen && window.innerWidth >= 999, // Large screens (≥999px)
            'right-0 w-[85vw] sm:w-[50vw]':
              sidebarOpen && window.innerWidth < 999, // Small screens (<999px)
            'right-[-85vw] sm:right-[-50vw] w-[85vw] sm:w-[50vw]':
              !sidebarOpen && window.innerWidth < 999, // Small screens (<999px)
            'hidden': !sidebarOpen,
          },
        )}
        ref={templateSidebar}
      >
        <div className='border-b h-[72px] flex items-center'>
          <Typography variant='subheading'>
            {staticText.addMessageTemplate}
          </Typography>
          <MdOutlineClose
            className='absolute top-4 right-4 cursor-pointer size-5 text-mouseGrey'
            onClick={handleSidebar}
          />
        </div>
        <div className='flex flex-col flex-grow h-[calc(100vh-72px)] justify-between pb-5'>
          <div className='space-y-5 mt-5 flex-grow flex flex-col'>
            <Controller
              control={control}
              name='title'
              rules={{ required: `*${staticText.title} ${common.isRequired}` }}
              render={({ field: { onChange, value, name } }) => (
                <FormField
                  title={staticText.title}
                  placeholder={staticText.titlePlaceholder}
                  name={name}
                  value={value}
                  isRequired
                  className='h-10'
                  labelClassName='h-6'
                  onChange={onChange}
                  errors={isSubmitted ? errors : {}}
                />
              )}
            />
            <div className='flex flex-col flex-grow'>
              <Typography className='flex md:text-sm capitalize font-semibold'>
                {staticText.content}
                <span className='text-redColor text-xl ml-1'>*</span>
              </Typography>
              <Controller
                name='content'
                rules={{
                  required: `*${staticText.content} ${common.isRequired}`,
                }}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <>
                    <Textarea
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                        adjustTextareaHeight(e.target);
                      }}
                      maxLength={2000}
                      placeholder={staticText.contentPlaceholder}
                      name='content'
                      errors={errors}
                      ref={(el) => el && adjustTextareaHeight(el)}
                      className={cn(
                        'border-greyWhite rounded-xl resize-none w-full p-2',
                        'placeholder:text-quickSilver placeholder:text-sm text-sm md:text-sm font-medium text-primaryBlack',
                        {
                          'min-h-40 max-h-[calc(100vh-320px)] overflow-auto':
                            value.length > 0,
                          'h-40': value.length === 0,
                        },
                      )}
                    />
                    <div className='w-full px-2 flex justify-end'>
                      <Typography className='text-[10px] md:text-xs text-right '>
                        {value.length}/2000
                      </Typography>
                    </div>
                  </>
                )}
              />
            </div>
          </div>
          <Button
            className='!mt-3'
            onClick={handleSubmit(handleCreateTemplateSubmit)}
            loading={createTemplateMutation.isPending}
            disabled={!isDirty || createTemplateMutation.isPending}
          >
            {common.add}
          </Button>
        </div>
      </div>

      {/* side bar end */}
      <div className='relative text-mouseGrey'>
        <div
          onClick={() => setOpenTemplateBox(!openTemplateBox)}
          className='cursor-pointer flex items-center justify-center h-full'
        >
          <GoRepoTemplate size={22} />
        </div>
        <div
          ref={templateBoxRef}
          className={cn(
            'absolute space-y-1 rounded-md chatTemplate w-56 xs:w-72 py-5 px-3 xs:p-3 h-72 bottom-11 -right-1 border-[1px] bg-white shadow-md hidden',
            { block: openTemplateBox },
          )}
        >
          <span className='flex items-center justify-between mb-1.5'>
            <Typography className='font-bold'>
              {staticText.templates}
            </Typography>
            <TooltipComponent
              text={staticText.addNewTemplate}
              align='center'
              className='w-fit'
              triggerClassName='max-w-fit'
              directionalArrow
              trigger={
                <span>
                  <HiOutlinePlusSm
                    className='text-primary text-xl cursor-pointer'
                    onClick={handleSidebar}
                  />
                </span>
              }
            />
          </span>
          <SearchBar
            searchText={searchText}
            setSearchText={setSearchText}
            containerClassName='h-8 rounded-md'
          />
          <div id='scrollableDiv' className='overflow-y-auto h-[185px] !mt-3'>
            <InfiniteScroll
              dataLength={templates.length}
              next={fetchNextPage}
              hasMore={hasNextPage}
              loader={<CircularLoader />}
              scrollableTarget='scrollableDiv'
              className='space-y-3'
            >
              {Content()}
            </InfiniteScroll>
          </div>
          <div className='absolute right-1 -bottom-1.5 size-3 bg-white border-r border-b rotate-45 transform -translate-x-1/2 z-20' />
        </div>
      </div>
      {idForDelete ? (
        <ConfirmationModal
          open={!!idForDelete}
          primaryBtnLabel={strings.common.delete}
          primaryBtnAction={handleDeleteModal}
          isLoading={deleteTemplateMutation.isPending}
          handleCloseModal={() => setIdForDelete(null)}
          icon={<HiOutlineTrash className='size-6 text-tomatoRed' />}
          textDesc={staticText.deleteTemplateMessage.replace(
            'this',
            `"${idForDelete.name}"`,
          )}
          containerClassName='py-5'
          key={templates.length}
        />
      ) : null}
    </>
  );
};

export default TemplateSection;

import React from 'react';

import { Switch } from './ui/switch';
import { Typography } from './Typography';

interface Props {
  title: string;
  isChecked: boolean;
  onClick: () => void;
}

const ToggleButton: React.FC<Props> = ({ title, isChecked, onClick }) => {
  return (
    <div className='flex justify-between items-center gap-2'>
      <Typography className='font-semibold'>{title}</Typography>
      <Switch checked={isChecked} onCheckedChange={onClick} />
    </div>
  );
};

export default ToggleButton;

import React from 'react';
import { FieldErrors } from 'react-hook-form';

import { Label } from '@/components/ui/label';
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group';
import { cn } from '@/lib/utils';
import CustomRadioLabel from '@/pages/CreateTicket/components/CustomRadioLabel';
import { ErrorMessage as HookFormErrorMessage } from '@hookform/error-message';

type OptionType = string | { label: string; value: string };

interface Props {
  name: string;
  options: OptionType[];
  value?: string | number | boolean;
  onChange: (value: string) => void;
  conditionalContent?: (value: string) => React.ReactNode;
  containerClassName?: string;
  customLabel?: boolean;
  errors?: FieldErrors;
  contentClassName?: string;
}

const RadioButtonGroup: React.FC<Props> = ({
  name,
  options,
  value,
  onChange,
  conditionalContent,
  containerClassName,
  customLabel,
  errors,
  contentClassName,
}) => {
  const handleValueChange = (val: string) => {
    let newValue: string | boolean = val;
    if (val === 'true') {
      newValue = true;
    } else if (val === 'false') {
      newValue = false;
    }
    onChange(newValue as string);
  };

  return (
    <>
      <RadioGroup
        value={String(value)}
        onValueChange={handleValueChange}
        className={cn('flex justify-between h-9', containerClassName)}
      >
        {options.map((option, index) => {
          const isStringOption = typeof option === 'string'; // Check if option is a string
          const optionLabel = isStringOption ? option : option.label; // Use option as label for string type
          const optionValue = isStringOption ? option : option.value; // Use option as value for string type

          return (
            <div
              key={`Radio-${optionValue}-${index}`}
              className={cn('my-auto flex-col', contentClassName)}
            >
              <div className='flex items-center space-x-2 h-full'>
                <RadioGroupItem
                  value={optionValue}
                  id={`${optionValue}-${name}`}
                />
                {customLabel ? (
                  <CustomRadioLabel htmlFor={optionValue}>
                    {optionLabel}
                  </CustomRadioLabel>
                ) : (
                  <Label htmlFor={`${optionValue}-${name}`}>
                    {optionLabel}
                  </Label>
                )}
              </div>
              {conditionalContent?.(optionValue)}
            </div>
          );
        })}
      </RadioGroup>
      {errors && (
        <HookFormErrorMessage
          errors={errors}
          name={String(name)}
          render={({ message }) => (
            <p className='text-redColor text-xs'>{message}</p>
          )}
        />
      )}
    </>
  );
};

export default RadioButtonGroup;

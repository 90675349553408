import { ReactElement } from 'react';
import { MdOutlineApproval } from 'react-icons/md';

import {
  CandidateIcon,
  ChatIcon,
  DashboardIcon,
  ProfileIcon,
  TicketsIcon,
} from '@/assets/svgs';
import RequestIcon from '@/assets/svgs/Request';
import { strings } from '@/locales';
import { useAuth } from '@/provider/AuthProvider';
import { ROUTES } from '@/routes';

import { USER_TYPE } from '.';

export interface SidebarItem {
  name: string;
  icon: ReactElement;
  location: string;
  roles: Record<USER_TYPE, boolean>;
}

// Utility function to assign roles
const withRoles = (roles: USER_TYPE[]): Record<USER_TYPE, boolean> => {
  return roles.reduce(
    (acc, role) => {
      acc[role] = true;
      return acc;
    },
    {} as Record<USER_TYPE, boolean>,
  );
};

// Custom hook to generate sidebar items
export const useSidebarItems = (): SidebarItem[] => {
  const { isRecruiter, isRecruitmentManager, user } = useAuth();

  const { dashboard, candidates, chat, tickets, request, users, approvals } =
    strings.sidebarRoutes;
  const {
    DASHBOARD,
    CANDIDATE,
    CHAT,
    TICKETS,
    REQUEST,
    USERS,
    TICKETS_WITH_TAB,
    // CANDIDATE_WITH_TABS,
    APPROVALS,
  } = ROUTES;

  return [
    {
      name: dashboard,
      icon: <DashboardIcon />,
      location: DASHBOARD,
      roles: {
        ...withRoles([USER_TYPE.RECRUITER]),
        ...(isRecruitmentManager ? { [USER_TYPE.RECRUITER]: true } : {}),
      },
    },
    {
      name: users,
      icon: <ProfileIcon />,
      location: USERS,
      roles: withRoles([USER_TYPE.ADMIN]),
    },

    {
      name: candidates,
      icon: <CandidateIcon />,
      // location: isRecruiter ? CANDIDATE_WITH_TABS : CANDIDATE,
      location: CANDIDATE,
      roles: withRoles([USER_TYPE.RECRUITER, USER_TYPE.ADMIN]),
    },
    ...(!user?.subRole
      ? [
          {
            name: chat,
            icon: <ChatIcon />,
            location: CHAT,
            roles: withRoles([
              USER_TYPE.COMPANY_CLIENT,
              USER_TYPE.RECRUITER,
              USER_TYPE.ADMIN,
            ]),
          },
        ]
      : []),
    {
      name: tickets,
      icon: <TicketsIcon />,
      location: isRecruiter ? TICKETS_WITH_TAB : TICKETS,
      roles: withRoles([
        USER_TYPE.COMPANY_CLIENT,
        USER_TYPE.RECRUITER,
        USER_TYPE.ADMIN,
      ]),
    },
    ...(!user?.subRole || user.profileType === USER_TYPE.COMPANY_CLIENT
      ? [
          {
            name: request,
            icon: <RequestIcon />,
            location: REQUEST,
            roles: withRoles([USER_TYPE.ADMIN, USER_TYPE.COMPANY_CLIENT]),
          },
        ]
      : []),
    ...(user?.subRole
      ? [
          {
            name: approvals,
            icon: <MdOutlineApproval size={20} />,
            location: APPROVALS,
            roles: withRoles([USER_TYPE.ADMIN]),
          },
        ]
      : []),
  ];
};

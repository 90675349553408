import React, { ReactNode } from 'react';
import { IoMdInformationCircleOutline } from 'react-icons/io';

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { cn } from '@/lib/utils';

type TooltipComponentProps = {
  trigger?: ReactNode;
  text?: string;
  children?: ReactNode;
  className?: string;
  align?: 'end' | 'center' | 'start';
  directionalArrow?: boolean;
  showTooltip?: boolean;
  triggerClassName?: string;
};

type TooltipContentProps = TooltipComponentProps & {
  text?: never;
};

type TooltipTextProps = TooltipComponentProps & {
  children?: never;
};

const TooltipComponent: React.FC<TooltipContentProps | TooltipTextProps> = ({
  trigger = <IoMdInformationCircleOutline size={16} />,
  text,
  children,
  align = 'end',
  className = 'max-w-64',
  directionalArrow = false,
  showTooltip = true,
  triggerClassName,
}) => {
  const [open, setOpen] = React.useState(false);

  // To fix warning for nested button
  const isButton = () => {
    if (React.isValidElement(trigger)) {
      const { type } = trigger;

      if (typeof type === 'string') {
        return type === 'button';
      }
    }
    return false;
  };

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger
          {...(isButton() ? { asChild: true } : {})}
          className={cn('flex justify-center items-center ', triggerClassName)}
          onClick={(e) => {
            e.preventDefault();
            setOpen(!open);
          }}
          onTouchStart={() => setOpen(!open)}
          onKeyDown={(e) => {
            e.preventDefault();
            e.key === 'Enter' && setOpen(!open);
          }}
        >
          {trigger}
        </TooltipTrigger>
        {showTooltip ? (
          <TooltipContent
            className={cn(
              {
                'before:w-2 before:h-2 before:rotate-45 before:shadow-md before:bg-white before:absolute before:-bottom-1 before:left-0 before:right-0 before:mx-auto':
                  directionalArrow,
              },
              className,
            )}
            align={align}
          >
            {text ? <p>{text}</p> : children}
          </TooltipContent>
        ) : null}
      </Tooltip>
    </TooltipProvider>
  );
};

export default TooltipComponent;

import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';

import { IUser } from '@/@types/auth';
import { SUB_ROLE, USER_TYPE } from '@/constants';
import {
  getAuthTokenFromLS,
  getUserDetailsFromLS,
  setTokenToLS,
  setUserDetailToLS,
} from '@/utils/localstorage';

interface IAuthContextType {
  user: IUser | null;
  setUser: React.Dispatch<React.SetStateAction<IUser | null>>;
  token: string | null;
  setToken: React.Dispatch<React.SetStateAction<string | null>>;
  isAdmin: boolean;
  isRecruiter: boolean;
  isCompanyClient: boolean;
  hasSubRole: boolean;
  isRecruitmentManager: boolean;
}

const AuthContext = createContext<IAuthContextType>({
  user: null,
  setUser: () => {},
  token: null,
  setToken: () => {},
  isAdmin: false,
  isRecruiter: false,
  isCompanyClient: false,
  hasSubRole: false,
  isRecruitmentManager: false,
});

export const AuthProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [user, setUser] = useState<IUser | null>(null);
  const [token, setToken] = useState<string | null>(null);

  const hasSubRole = !!user?.subRole;
  const userProfileType = user?.profileType;
  const isAdmin = userProfileType === USER_TYPE.ADMIN;
  const isRecruiter = userProfileType === USER_TYPE.RECRUITER;
  const isCompanyClient = userProfileType === USER_TYPE.COMPANY_CLIENT;
  const isRecruitmentManager = user?.subRole === SUB_ROLE.RECRUITMENT_MANAGER;

  useEffect(() => {
    if (user === null) {
      const storedUserInLS = getUserDetailsFromLS();
      const storedTokenInLS = getAuthTokenFromLS();
      setUser(storedUserInLS as IUser);
      setToken(storedTokenInLS as string);
    } else {
      setUserDetailToLS(user);
    }
  }, [user]);

  useEffect(() => {
    if (token !== null) {
      setTokenToLS(token);
    }
  }, [token]);

  const value = {
    user,
    setUser,
    token,
    setToken,
    isAdmin,
    isRecruiter,
    isCompanyClient,
    hasSubRole,
    isRecruitmentManager,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = (): IAuthContextType => useContext(AuthContext);

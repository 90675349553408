import React, { useState } from 'react';

import Container from '@/components/Container';
import Modal from '@/components/Modal';
import { Typography } from '@/components/Typography';
import { getDashboardCardsData } from '@/constants/dashboard';
import { useGenericQuery } from '@/hooks/useQueryData';
import { cn } from '@/lib/utils';
import { strings } from '@/locales';
import { useAuth } from '@/provider/AuthProvider';
import { useSidebarContext } from '@/provider/SidebarProvider';
import {
  fetchDashboardAllTicketsCount,
  fetchDashboardMyTicketsCount,
  fetchDashboardOverallPerformance,
  fetchDashboardSales,
  fetchDashboardTotalHeadcounts,
  fetchDashboardTotalSales,
  ICompantForHeadcount,
  IMyTicketsCountResponse,
  IOverallPerformanceResponse,
  IRecruiter,
  IRecruiterListResponse,
  ISalesResponse,
  ITotalHeadCountResponse,
} from '@/services/dashboard';
import { formatCurrency, GetFormattedName } from '@/utils/common';

import DashboardCard from './DashboardCard';

const StatsCard = () => {
  const { sidebarExpand } = useSidebarContext();
  const { isRecruiter, isRecruitmentManager } = useAuth();
  const [modalOpen, setModalOpen] = useState('');

  const { data: allTicketsCount, isLoading: allTicketsLoading } =
    useGenericQuery(
      ['fetchDashboardAllTicketsCount'],
      fetchDashboardAllTicketsCount,
      { enabled: isRecruiter },
    );
  const { data: myTicketsCount, isLoading: myTicketsLoading } = useGenericQuery(
    ['fetchDashboardMyTicketsCount'],
    fetchDashboardMyTicketsCount,
    { enabled: isRecruiter },
  );
  const { data: sales, isLoading: salesLoading } = useGenericQuery(
    ['fetchDashboardSales'],
    fetchDashboardSales,
    { enabled: isRecruiter },
  );

  const { data: totalSales, isLoading: totalSalesLoading } = useGenericQuery(
    ['fetchDashboardTotalSales'],
    fetchDashboardTotalSales,
    {
      enabled: isRecruitmentManager,
    },
  );
  const { data: headcountData, isLoading: headcountDataLoading } =
    useGenericQuery(
      ['fetchDashboardTotalHeadcounts'],
      fetchDashboardTotalHeadcounts,
      { enabled: isRecruitmentManager },
    );
  const { data: overallPerformanceData, isLoading: performanceLoading } =
    useGenericQuery(
      ['fetchDashboardOverallPerformance'],
      fetchDashboardOverallPerformance,
      { enabled: isRecruitmentManager },
    );

  const DASHBOARD_CARDS_DATA = getDashboardCardsData(
    allTicketsCount ??
      Number((headcountData as ITotalHeadCountResponse)?.totalHeadCount),
    allTicketsLoading || headcountDataLoading,
    (myTicketsCount as IMyTicketsCountResponse)?.myTicketsCount ??
      (overallPerformanceData as IOverallPerformanceResponse)?.totalHeadCount,
    (myTicketsCount as IMyTicketsCountResponse)?.completedHiringTicketsCount ??
      (overallPerformanceData as IOverallPerformanceResponse)?.totalHiredCount,
    myTicketsLoading || performanceLoading,
    isRecruitmentManager
      ? (totalSales as IRecruiterListResponse)?.allTotalSales
      : (sales as ISalesResponse)?.totalSales,
    isRecruitmentManager
      ? (totalSales as IRecruiterListResponse)?.allTotalHired
      : (sales as ISalesResponse)?.totalHiredCandidate,
    salesLoading || totalSalesLoading,
    isRecruitmentManager,
  );

  return (
    <>
      <Container
        className={cn(
          'mt-4 grid gap-7 p-5 md:p-5 grid-cols-1 lg:grid-cols-3',
          sidebarExpand ? 'lg:grid-cols-2 xl:grid-cols-3' : 'sm:grid-cols-2',
        )}
      >
        {DASHBOARD_CARDS_DATA.map((card, index) => (
          <DashboardCard
            key={index}
            keyId={index}
            title={card.title}
            count={card.count}
            supportCount={card.supportCount}
            countUnit={card.countUnit}
            text={card.text}
            isLoading={card.isLoading}
            setModalOpen={setModalOpen}
          />
        ))}
      </Container>
      <Modal
        open={!!modalOpen}
        handleCloseModal={() => setModalOpen('')}
        title={modalOpen}
        titleClassName='text-left'
        contentClassName={cn({
          'max-w-3xl': modalOpen === strings.dashboard.totalSales,
        })}
      >
        <>
          {modalOpen === strings.dashboard.totalHeadcount ? (
            <div className='-mt-2 max-h-96 overflow-scroll'>
              <div className='gap-1 grid grid-cols-3 px-1'>
                <Typography className='block text-base md:text-lg font-bold mb-2 col-span-2'>
                  {strings.dashboard.clientName}
                </Typography>
                <Typography className='block font-bold mb-1 text-base md:text-lg text-center'>
                  {strings.dashboard.totalHeadcount}
                </Typography>
              </div>
              {(headcountData as ITotalHeadCountResponse)?.companies?.map(
                (item) => (
                  <div
                    key={item?.companyName}
                    className='gap-1 grid grid-cols-3 border-t p-1.5'
                  >
                    <Typography className='block font-bold mb-1 col-span-2 text-primary'>
                      {item?.companyName}
                    </Typography>
                    <Typography className='block font-bold mb-1 text-primary text-center'>
                      {item?.totalHeadCount}
                    </Typography>
                    {(item?.clients as ICompantForHeadcount[])?.map((item) => (
                      <div
                        className='grid grid-cols-3 col-span-3'
                        key={item?.clientName}
                      >
                        <Typography className='block text-xs md:text-sm font-semibold mb-1 col-span-2 ml-4'>
                          {item?.clientName}
                        </Typography>
                        <Typography className='block text-xs md:text-sm font-semibold mb-1 text-center'>
                          {item?.totalHeadCount}
                        </Typography>
                      </div>
                    ))}
                  </div>
                ),
              )}
            </div>
          ) : (
            <div className='-mt-3 max-h-96 overflow-scroll'>
              <div className='gap-1 sm:grid grid-cols-4 items-center hidden '>
                <Typography className='block text-base md:text-lg font-bold mb-2'>
                  {strings.dashboard.recruiterName}
                </Typography>
                {['totalHired', 'totalSales', 'percentage'].map((key) => (
                  <Typography
                    key={key}
                    className='block text-sm md:text-base font-bold mb-2 text-center'
                  >
                    {strings.dashboard[key as keyof typeof strings.dashboard]}
                  </Typography>
                ))}
              </div>
              {(totalSales as IRecruiterListResponse)?.recruiters?.map(
                (item) => (
                  <div
                    key={item?._id}
                    className='gap-1 grid sm:grid-cols-4 sm:items-center border-t p-1.5'
                  >
                    <Typography className='block font-semibold mb-1'>
                      <span className='sm:hidden text-base'>
                        {strings.dashboard.recruiterName}:
                      </span>{' '}
                      {GetFormattedName(item)}
                    </Typography>
                    {['totalHired', 'totalSales', 'percentage'].map((key) => (
                      <Typography
                        key={key}
                        className='block font-bold mb-1 text-primary sm:text-center'
                      >
                        <span className='sm:hidden text-base text-primaryBlack'>
                          {
                            strings.dashboard[
                              key as keyof typeof strings.dashboard
                            ]
                          }
                        </span>
                        {key === 'totalSales'
                          ? formatCurrency(
                              Number(item[key as keyof IRecruiter]),
                            )
                          : item[key as keyof IRecruiter]}
                        {key === strings.dashboard.percentage.toLowerCase()
                          ? '%'
                          : ''}
                      </Typography>
                    ))}
                  </div>
                ),
              )}
            </div>
          )}
        </>
      </Modal>
    </>
  );
};

export default StatsCard;

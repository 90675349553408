export const REST_SUB_URL = {
  LOGIN: '/auth/login',
  FORGOT_PASSWORD: '/auth/forgot-password',
  VERIFY_RESET_PASSWORD: '/auth/verify-reset-password',
  RESET_PASSWORD: '/auth/reset-password',
  PROFILE: '/profile',
  ADMIN_PROFILE_UPDATE: '/profile/admin',
  TICKETS: '/ticket',
  GET_MY_TICKETS: '/ticket/user/my-tickets',
  USER_LIST: '/users/admin',
  USER_DETAIL: '/users',
  DELETE_USER: '/auth/delete-account',
  REGESTER_ACCOUNT: '/auth/register-accounts',
  ASSIGN_TICKET_TO_SELF: '/ticket/user/assignee',
  ASSIGN_TICKET: '/ticket/admin/assignee',
  GET_RECRUITERS: '/users/all/recuriters',
  GET_DEPARTMENTS: '/department',
  GET_COMPANIES: '/department/companies',
  UPDATE_NOTES: '/candidate/notes',
  CANDIDATES: '/candidate',
  CANDIDATES_ENDORSEMENTS_DETAIL: '/candidate/detail/endorsed',
  CANDIDATES_IAF_DETAIL: '/recruiter-iaf/detail/iaf',
  CANDIDATES_PAN_FORM_DETAIL: '/pan-form/detail/pan',
  REMOVE_FROM_SHORTLISTED_CANDIDATE: '/candidate/move-to-all',
  MOVE_TO_SHORTLISTEDED_CANDIDATE: '/candidate/move-to-shortlist',
  DOWNLOAD_CANDIDATE_RESUMES: '/candidate/download/resumes',
  CANDIDATE_BULK_INSERTION: '/candidate/bulk-insertion',
  GET_TICKET_WITH_COMPANIES: '/ticket/active/companies',
  UPDATE_CANDIDATE_TAGS: '/candidate/tags',
  GET_TICKETS_CANDIDATES: '/requests/candidates',
  MOVE_TO_APPLIED_SHORTLIST: '/requests/move-to-shortlist',
  SEND_ENDORSEMENT: '/requests/send-endrosed',
  REQUEST: '/requests',
  REQUEST_DETAIL: '/requests/detail',
  GET_CHAT_LIST: '/chat/conversations',
  GET_CHAT: '/chat/conversation',
  CREATE_CHAT: '/chat/create-conversation',
  ASSOCIATE_CHAT: '/ticket/associate/tickets',
  GET_ALL_NOTIFICATIONS: '/notifications',
  MARK_NOTIFICATION_READ: '/notifications/mark-as-read',
  MARK_ALL_NOTIFICATIONS_READ: '/notifications/mark-as-all-read',
  READ_MESSAGES: '/chat/read-messages',
  CHAT_CANDIDATE_LIST: '/candidate/chat/list',
  GET_UN_READ_NOTIFICATIONS_COUNT: '/notifications/un-read-count',
  CLEAR_UN_READ_NOTIFICATIONS_COUNT: '/notifications/clear-read-count',
  CREATE_BULK_CHAT: '/chat/bulk-create-conversations',
  DASHBOARD_MY_TICKETS_COUNTS: '/dashboard/my-tickets-count',
  DASHBOARD_ALL_TICKETS_COUNTS: '/dashboard/all-tickets-count',
  DASHBOARD_TOTAL_HIRED_SALES: '/dashboard/total-hired-sales',
  DASHBOARD_TOTAL_SALES: '/recruiter-dashboard/total-sales',
  DASHBOARD_MANPOWER_DATA_FOR_MANAGER: '/recruiter-dashboard/manpower-form',
  DASHBOARD_SOURCES: '/dashboard/source',
  DASHBOARD_SETTINGS: '/dashboard/settings',
  DASHBOARD_SETTINGS_FOR_REC_MANAGER: '/recruiter-dashboard/settings',
  REQUEST_TICKET_CLOSURE: '/ticket/close/ticket-request',
  CLOSE_TICKET: '/ticket/close',
  DASHBOARD_MANPOWER_REQUEST_FORM: '/dashboard/manpower-form',
  DASHBOARD_STATISTICS: '/dashboard/statistics',
  PAN_FORM: '/pan-form',
  IEF_FORM: '/recruiter-iaf',
  COMPANY_CLIENTS: '/users/all/company-client',
  CANDIDATE_RANK_IN_TICKET: '/requests/edit-rank',
  GET_APPROVALS: '/pan-form',
  GET_ACCOUNT_OFFICER_CLOSED_APPROVALS: '/pan-form/clousre/account-officer',
  GET_RECRUITMENT_MANAGER_CLOSED_APPROVALS:
    '/pan-form/clousre/recruitment-manager',
  ACCOUNT_OFFICER_APPROVAL: '/pan-form/account-officer',
  RECRUITMENT_MANAGER_APPROVAL: '/pan-form/recruitment-manager',
  CREATE_COMPANY_CLIENT_IAF: '/company-client-iaf',
  COMPANY_CLIENT_IAF_DETAILS: '/company-client-iaf/detail/iaf',
  TICKET_DOCUMENTS: '/ticket/qualification',
  GET_EMPLOYEES: '/ticket/employee/tickets',
  CHAT_TEMPLATES: '/chat-template',
  UPLOAD_RESUME: '/jobs/upload-resume',
  EMAIL_NOTIFICATIONS: '/auth/settings',
  TOTAL_HEAD_COUNT: '/recruiter-dashboard/total-head-count',
  OVERALL_PERFORMANCE: '/recruiter-dashboard/overall-perfomance',
  DOCUMENTS: '/documents',
  DOCUMENTS_MULTIPLE: '/documents/multiple',
};

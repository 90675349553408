/* eslint-disable max-lines */
import React, { useCallback, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { FiTrash2 } from 'react-icons/fi';
import { HiOutlineTrash } from 'react-icons/hi';

import { IDocumentsData } from '@/@types/candidate';
import DefaultIcon from '@/assets/svgs/defaultIcon.svg';
import PDFicon from '@/assets/svgs/pdfIcon.svg';
import WordIcon from '@/assets/svgs/wordIcon.svg';
import ConfirmationModal from '@/components/ConfirmationModal';
import Container from '@/components/Container';
import FileDropContainer from '@/components/FileDropContainer';
import StateIndicator from '@/components/StateIndicator';
import { Typography } from '@/components/Typography';
import { Button } from '@/components/ui/button';
import { AcceptedFileFormats } from '@/constants';
import { DOCUMENT_LIST } from '@/constants/candidate';
import { useGenericMutation } from '@/hooks/useMutationData';
import { useGenericQuery } from '@/hooks/useQueryData';
import { strings } from '@/locales';
import {
  deleteDocument,
  getDocuments,
  uploadMultipleDocument,
} from '@/services/candidate';

const Documents: React.FC<{
  candidateId?: string;
}> = ({ candidateId }) => {
  const { control, watch, handleSubmit, reset } = useForm<IDocumentsData>({
    defaultValues: {
      documentSSS: null,
      documentTIN: null,
      documentMedicalCertificate: null,
      documentNBI: null,
      documentPoliceClearance: null,
      documentBirthCertificate: null,
      documentBarangayClearance: null,
      documentMarriageCertificate: null,
      philHealthID: null,
      pagIBIGID: null,
      documentDiplomaOrCertificate: null,
      documentCertificateOfEmployment: null,
    },
  });

  const staticText = strings.candidateDocuments;
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState('');
  const [uploadProgress, setUploadProgress] = useState(0);
  const handleProgressUpdate = useCallback((percentage: number) => {
    setUploadProgress(percentage);
  }, []);

  const watchAllFields = watch();

  const {
    data: documents,
    isLoading: loadingDocuments,
    refetch,
  } = useGenericQuery(['documents'], () => getDocuments(candidateId));

  const filledDocumentList = DOCUMENT_LIST.map((dummyDoc) => {
    const matchingDocument = Array.isArray(documents)
      ? documents.find((doc) => doc.name === dummyDoc.name)
      : null;
    return {
      ...dummyDoc,
      url: matchingDocument?.url || '',
      type: matchingDocument?.type || '',
      fileSize: matchingDocument?.size || '',
    };
  });

  const documentsWithUrl = filledDocumentList.filter(
    (doc) => doc.url && doc.url.trim() !== '',
  );

  const documentsWithoutUrl = filledDocumentList.filter(
    (doc) => !doc.url || doc.url.trim() === '',
  );

  const deleteDocumentMutation = useGenericMutation<
    { fileName: string; candidateId?: string },
    string | boolean
  >(deleteDocument, {
    onSuccess: (response) => {
      if (response) {
        setSelectedFile('');
        refetch();
      }
      setOpenDeleteModal(false);
    },
  });

  const handleDelete = () => {
    if (selectedFile) {
      deleteDocumentMutation.mutate({ fileName: selectedFile, candidateId });
    }
  };

  const uploadDocumentationMutation = useGenericMutation<
    { payload: IDocumentsData; candidateId?: string },
    boolean
  >(uploadMultipleDocument, {
    onSuccess: (response) => {
      if (response) {
        refetch();
        setSelectedFile('');
        reset();
        setUploadProgress(0);
      }
    },
  });

  const handleSave: SubmitHandler<IDocumentsData> = (data) => {
    uploadDocumentationMutation.mutate({
      payload: {
        ...data,
        setPercent: handleProgressUpdate,
      },
      candidateId,
    });
  };

  const getFileIcon = (fileName: string) => {
    const extension = fileName.split('.').pop()?.toLowerCase();
    switch (extension) {
      case 'pdf':
        return PDFicon;
      case 'doc':
      case 'docx':
        return WordIcon;
      default:
        return DefaultIcon;
    }
  };

  const hasChanges = Object.values(watchAllFields).some(
    (file) => file !== null,
  );

  const handleDeleteModalOpen = (fieldName: string) => {
    setSelectedFile(fieldName);
    setOpenDeleteModal(true);
  };
  const nonNullFieldKeys = Object.keys(watchAllFields).filter(
    (key) => watchAllFields[key as keyof IDocumentsData] !== null,
  );

  return (
    <>
      <div className='flex items-center my-6'>
        <Typography variant='heading' className='md:text-2xl'>
          {staticText.documents}
        </Typography>
      </div>
      <Container>
        {loadingDocuments ? (
          <div className='min-h-60 flex items-center justify-center'>
            <StateIndicator state='Loading' />
          </div>
        ) : (
          <>
            {documentsWithoutUrl.length ? (
              <>
                <Typography variant='subheading' className='font-bold'>
                  {staticText.uploadDocuments}
                </Typography>

                <div className='my-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6'>
                  {documentsWithoutUrl?.map((item) => (
                    <div key={item.name} className='flex flex-col h-full'>
                      <div className='mb-1'>
                        <Typography
                          variant='subheading'
                          className='font-extrabold md:text-base'
                        >
                          {item?.name}
                        </Typography>
                      </div>
                      <Controller
                        name={item.keyName as keyof IDocumentsData}
                        control={control}
                        render={({ field: { onChange, name, value } }) => (
                          <FileDropContainer
                            setFile={(file) => onChange(file)}
                            acceptedFileFormat={AcceptedFileFormats}
                            fileName={(value as File)?.name}
                            buttonText={staticText.upload}
                            iconClassName='bg-primary rounded-full text-white text-4xl p-2'
                            dropzoneClassName='h-full h-24'
                            uploadingDivClassName='h-full'
                            progress={
                              nonNullFieldKeys.includes(name)
                                ? uploadProgress
                                : 0
                            }
                          />
                        )}
                      />
                    </div>
                  ))}
                </div>
                {hasChanges ? (
                  <div className='mt-8 flex justify-end'>
                    <Button
                      onClick={handleSubmit(handleSave)}
                      disabled={
                        !hasChanges ||
                        uploadDocumentationMutation.status === 'pending'
                      }
                      loading={uploadDocumentationMutation.status === 'pending'}
                      className='bg-primary text-white w-32 h-11'
                    >
                      {staticText.save}
                    </Button>
                  </div>
                ) : null}
              </>
            ) : null}
            {documentsWithUrl.length ? (
              <>
                <div className='block mb-6'>
                  <Typography variant='subheading' className='font-bold'>
                    {staticText.uploadedDocuments}
                  </Typography>
                </div>
                <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6'>
                  {documentsWithUrl.map((item) => (
                    <div className='block' key={item?.name}>
                      <Typography
                        variant='subheading'
                        className='font-extrabold md:text-base mb-2'
                      >
                        {staticText[item?.name as keyof typeof staticText]}
                      </Typography>
                      <a
                        className='border-greyWhite border flex items-center p-3 sm:p-4 bg-simplyViolet rounded-xl cursor-pointer'
                        target='_blank'
                        rel='noreferrer'
                        href={item.url}
                      >
                        <div className='grid grid-cols-5 gap-3 w-full'>
                          <div className='col-span-4 flex gap-4 items-center'>
                            <div className='bg-white border-greyWhite border rounded size-8 sm:size-10 flex justify-center items-center flex-shrink-0'>
                              <img
                                src={getFileIcon(item?.url)}
                                alt='Document-icon'
                                className='size-6 sm:size-8'
                              />
                            </div>
                            <div className='flex flex-col overflow-hidden flex-grow'>
                              <Typography className='text-xs sm:text-sm text-mouseGrey  max-w-full truncate'>
                                {item?.name}
                              </Typography>
                              <Typography className='text-xs sm:text-sm text-mouseGrey '>
                                {item?.fileSize}
                              </Typography>
                            </div>
                          </div>
                          <div className='col-span-1 flex justify-end my-auto'>
                            <Button
                              variant='ghost'
                              size='icon'
                              onClick={(e) => {
                                e.preventDefault();
                                handleDeleteModalOpen(item?.name);
                              }}
                              className='text-redColor bg-white size-8 sm:size-10'
                              icon={<FiTrash2 size={16} />}
                            />
                          </div>
                        </div>
                      </a>
                    </div>
                  ))}
                </div>
              </>
            ) : null}
          </>
        )}
      </Container>
      <ConfirmationModal
        open={openDeleteModal}
        handleCloseModal={() => setOpenDeleteModal(false)}
        primaryBtnLabel={strings.common.delete}
        primaryBtnAction={handleDelete}
        icon={<HiOutlineTrash className='size-6 text-tomatoRed' />}
        textDesc={strings.common.deleteText}
        isLoading={deleteDocumentMutation.isPending}
        containerClassName='py-5'
      />
    </>
  );
};

export default Documents;

import { strings } from '@/locales';

export const PASSWORD_VALIDATIONS_POINTS = [
  strings.passwordValidationText.charLength,
  strings.passwordValidationText.lowerCase,
  strings.passwordValidationText.upperCase,
  strings.passwordValidationText.number,
  strings.passwordValidationText.specialChar,
];

export const INPUT_ERRORS = {
  EMAIL: {
    required: '*Email is required.',
    pattern: '*Enter a valid email.',
  },
  PASSWORD: {
    required: strings.passwordValidationText.required,
    minLength: strings.passwordValidationText.charLength,
    lowercase: strings.passwordValidationText.lowerCase,
    uppercase: strings.passwordValidationText.upperCase,
    number: strings.passwordValidationText.number,
    specialChar: strings.passwordValidationText.specialChar,
  },
  CONFIRM_PASSWORD: {
    required: '*Confirm Password is required.',
    match: '*Passwords do not match',
  },
  FIRST_NAME: {
    required: '*Full Name is required.',
  },
  LAST_NAME: {
    required: '*Full Name is required.',
  },
  E_MAIL: {
    required: '*Email is required.',
  },
  PHONE_NUMBER: {
    required: '*Phone Number is required.',
  },
  INVALID_EMAIL: {
    required: 'Invlid Email Format',
  },
  INVALID_PHONE_NUMBER: {
    required: 'Invlid Phone Number Format',
  },
  COMPANY_NAME: {
    required: '*Company Name is required.',
  },
  STATUS: {
    required: '*Status is required.',
  },
  TAGS: {
    required: '*Tag is required.',
  },
  JOB_POSITION: {
    required: '*Job Position is required.',
  },
  DEPARTMENT: {
    required: '*Department is required.',
  },
  NOTES: {
    required: '*Notes required.',
  },
  FILE_SIZE: {
    maxFileSize: '*File size exceeds 500 KB',
  },
  TICKET: {
    required: '*Ticket Name is required.',
  },
  FILE: {
    required: '*File is required.',
  },
  RANGE: {
    required: '*Both Min and Max values are required',
    validate: '*Min value must be smaller than Max value',
  },
  COMPUTER_LITERACY: {
    required: '*Computer Literacy is required.',
  },
  DATE_OF_ASSESSMENT: {
    required: '*Date of Assessment is required.',
  },
  RELEVANT_WORK_EXPERIENCE: {
    required: '*Work Experience is required.',
  },
  LEADERSHIP_BACKGROUND: {
    required: '*Leadership Background is required.',
  },
  ENGLISH_PROFICIENCY: {
    required: '*English Proficiency is required.',
  },
  COMMUNICATION: {
    required: '*Communication is required.',
  },
  PHYSICAL_ATTRIBUTES: {
    required: '*Physical Attributes are required.',
  },
  PLANS_TO_MIGRATE: {
    required: '*Plans to Migrate is required.',
  },
  AMENABILITY_WITH_FIELD_WORK: {
    required: '*Amenability with Field Work is required.',
  },
  AMENABILITY_WITH_SHIFTING_SCHEDULE: {
    required: '*Amenability with Shifting Schedule is required.',
  },
  AVAILABILITY_FOR_INTERVIEW: {
    required: '*Availability for Interview is required.',
  },
  AVAILABILITY_FOR_EMPLOYMENT: {
    required: '*Availability for Employment is required.',
  },
  RECOMMENDATION_OR_REMARKS: {
    required: '*Recommendation/Remarks is required.',
  },
  SUITABLE_FOR: {
    required: '*Suitable for is required.',
  },
  END_DATE: {
    validate: 'End date must be greater than or equal to start date.',
  },
  DESCREPTION: {
    required: '*Description is required.',
  },
  REQUIRED: {
    required: '*Required.',
  },
};
export const COMMON_ERROR = 'Something went wrong!';
export const COMMON_SUCCESS = 'Operation completed successfully!';

export const PAGE_SIZE_OPTIONS = Array.from({ length: 6 }, (_, i) =>
  (i + 5).toString(),
);

export const enum CONFIRMATION_MODAL {
  DELETE = 'Delete',
  REJECT = 'Reject',
}

export const enum USER_PROVIDER {
  EMAIL = 'EMAIL',
}

export enum USER_TYPE {
  RECRUITER = 'RECRUITER',
  CANDIDATE = 'CANDIDATE',
  COMPANY_CLIENT = 'COMPANY_CLIENT',
  ADMIN = 'ADMIN',
}

export const FILE_SIZE_LIMIT = 5 * 1024 * 1024; // 5MB

export enum LOCAL_CONSTANT {
  USER_TOKEN = 'token',
  USER = 'user',
}

export const enum SUB_ROLE {
  ACCOUNT_OFFICER = 'ACCOUNT_OFFICER',
  RECRUITMENT_MANAGER = 'RECRUITMENT_MANAGER',
}

export const mimeTypes: Record<string, string> = {
  '.pdf': 'application/pdf',
  '.docx':
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  '.doc': 'application/msword',
  '.jpeg': 'image/jpeg',
  '.png': 'image/png',
  '.csv': 'text/csv',
};

export const AcceptedFileFormats = ['.png', '.jpeg', '.pdf', '.docx'];

export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

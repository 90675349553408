import React, { useMemo } from 'react';

import { USER_TYPE } from '@/constants';
import { cn } from '@/lib/utils';
import { strings } from '@/locales';
import { useAuth } from '@/provider/AuthProvider';
import { useSidebarContext } from '@/provider/SidebarProvider';

import { SidebarItem, useSidebarItems } from '../../constants/sidebar';

import SidebarOption from './SidebarOption';

// Utility function to check role permissions
const hasAccess = (
  item: SidebarItem,
  isAdmin: boolean,
  isCompanyClient: boolean,
  isRecruiter: boolean,
  isRecruitmentManager: boolean,
) => {
  const roles = item.roles || {};

  return (
    (roles[USER_TYPE.ADMIN] && isAdmin) ||
    (roles[USER_TYPE.COMPANY_CLIENT] && isCompanyClient) ||
    (roles[USER_TYPE.RECRUITER] && (isRecruiter || isRecruitmentManager)) ||
    (!roles[USER_TYPE.ADMIN] &&
      !roles[USER_TYPE.COMPANY_CLIENT] &&
      !roles[USER_TYPE.RECRUITER])
  );
};

const SidebarTabs = () => {
  const { currentTab, sidebarExpand } = useSidebarContext();
  const { isAdmin, isCompanyClient, isRecruiter, isRecruitmentManager } =
    useAuth();
  const sidebarItems = useSidebarItems();

  const filteredItems: SidebarItem[] = sidebarItems.filter((item) =>
    hasAccess(
      item,
      isAdmin,
      isCompanyClient,
      isRecruiter,
      isRecruitmentManager,
    ),
  );

  const memoizedFilteredItems = useMemo(() => {
    if (isCompanyClient) {
      const ticketsIndex = filteredItems.findIndex(
        (item) => item.name === strings.sidebarRoutes.tickets,
      );
      const chatIndex = filteredItems.findIndex(
        (item) => item.name === strings.sidebarRoutes.chat,
      );

      if (
        ticketsIndex !== -1 &&
        chatIndex !== -1 &&
        ticketsIndex !== chatIndex
      ) {
        // Ensure both indices are within bounds
        if (
          ticketsIndex < filteredItems.length &&
          chatIndex < filteredItems.length
        ) {
          // Ensure the items at these indices are not undefined
          const ticketsItem = filteredItems[ticketsIndex];
          const chatItem = filteredItems[chatIndex];
          // Swap items for CC
          if (ticketsItem && chatItem) {
            const updatedItems = [...filteredItems];
            updatedItems[ticketsIndex] = chatItem;
            updatedItems[chatIndex] = ticketsItem;
            return updatedItems;
          }
        }
      }
    }
    return filteredItems;
  }, [isCompanyClient]);

  const currentTabArray = currentTab.split('-').map((tab) => tab.trim());

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      className={cn('mt-4 flex flex-col w-44 mx-3', {
        'items-center w-12': !sidebarExpand,
      })}
    >
      {memoizedFilteredItems.map((item) => {
        const selected = currentTabArray.some((tab) =>
          item.name.toLowerCase().includes(tab.toLowerCase()),
        );

        return (
          <SidebarOption
            key={item.location}
            selected={selected}
            icon={item.icon}
            name={item.name}
            location={item.location}
          />
        );
      })}
    </div>
  );
};

export default SidebarTabs;

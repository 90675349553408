import { RegisterOptions } from 'react-hook-form';
import { NavigateFunction } from 'react-router-dom';

import {
  EnumType,
  FilterField,
  FilterOptions,
  INameable,
  variantType,
} from '@/@types';
import { ISetNewPasswordFields } from '@/@types/auth';
import { INPUT_ERRORS, mimeTypes, SUB_ROLE, USER_TYPE } from '@/constants';

export const EmailRegex = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]+$/;
export const PhoneRegex = /^[0-9]{10}$/;
export const OnlyNumericStrings = /^(?!\d+$)[\s\S]+$/;

export const formatEmailAddress = (email: string) => {
  if (!email) return '';
  const [localPart, domain] = email.split('@');
  if (!localPart || !domain) return '';
  const maskedLocalPart = `${localPart.charAt(0)}**`;
  const [domainName, topLevelDomain] = domain.split('.');
  if (!domainName || !topLevelDomain) return '';
  const maskedDomain = `@***${domainName.slice(-2)}.${topLevelDomain}`;
  return maskedLocalPart + maskedDomain;
};

export const generateInitials = (name: string) => {
  const nameArray = name.split(' ');
  const initials = nameArray.map((part) => part.charAt(0)).join('');
  return initials.toUpperCase();
};

export const generateHexCode = (name: string) => {
  const asciiSum = name
    ?.split('')
    ?.filter((char) => char !== ' ') // Ignore spaces
    ?.reduce((sum, char) => sum + char.charCodeAt(0), 0);

  const adjustColor = (value: number) => {
    return ((value % 256) + 256) % 256;
  };

  const red = adjustColor(asciiSum || 0);
  const green = adjustColor((asciiSum || 0) * 2);
  const blue = adjustColor((asciiSum || 0) * 3);

  const hexCode = [red, green, blue]
    .map((value) => value.toString(16).padStart(2, '0'))
    .join('');

  return `#${hexCode.toUpperCase()}`;
};

const calculateLuminance = (r: number, g: number, b: number) => {
  const a = [r, g, b].map((value) => {
    const v = value / 255;
    return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
  });

  return a[0]! * 0.2126 + a[1]! * 0.7152 + a[2]! * 0.0722;
};

// Function to get the text color (black or white) based on the background color
export const getTextColor = (hex: string) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  const luminance = calculateLuminance(r, g, b);

  // Use white text for dark backgrounds, black text for light backgrounds
  return luminance < 0.5 ? '#FFFFFF' : '#000000';
};

export const getTagVariant = (text?: string): variantType => {
  if (!text) return 'blue';
  const normalizedText = text.toLowerCase();
  const variantMap = {
    'on hold': 'yellow',
    'shortlisted': 'purple',
    'rejected': 'red',
    'interviewing': 'pink',
    'interview cleared': 'orange',
    'endorsed': 'blue',
    'active': 'teal',
    'in active': 'orange',
    'hr orientation': 'green',
    'pre-deploy': 'brown',
    'approved': 'green',
    'pending approval': 'yellow',
  } as const;

  for (const key in variantMap) {
    if (normalizedText === key) {
      return variantMap[key as keyof typeof variantMap];
    }
  }

  return 'blue';
};

// Custom password validation function
export const validatePassword = (value: string) => {
  if (!/[a-z]/.test(value)) return INPUT_ERRORS.PASSWORD.lowercase;
  if (!/[A-Z]/.test(value)) return INPUT_ERRORS.PASSWORD.uppercase;
  if (!/\d/.test(value)) return INPUT_ERRORS.PASSWORD.number;
  if (!/[!@#$%^&*(),.?":{}|<>]/.test(value))
    return INPUT_ERRORS.PASSWORD.specialChar;
  return true;
};

// For extracting optional properties from any interface
export type OptionalProps<T> = {
  [K in keyof T as T extends { [P in K]: T[K] | undefined } ? K : never]?: T[K];
};

export const validateNewPasswordFields = (newPassword?: string) => {
  return {
    newPasswordRules: {
      required: INPUT_ERRORS.PASSWORD.required,
      validate: (value: string) => {
        if (value.length < 8) {
          return INPUT_ERRORS.PASSWORD.minLength;
        }
        return validatePassword(value);
      },
    } as RegisterOptions<ISetNewPasswordFields, 'password'>,

    confirmNewPasswordRules: {
      required: INPUT_ERRORS.CONFIRM_PASSWORD.required,
      validate: (value: string) => {
        if (newPassword && !value) {
          return INPUT_ERRORS.CONFIRM_PASSWORD.required;
        }
        if (newPassword !== value) {
          return INPUT_ERRORS.CONFIRM_PASSWORD.match;
        }
        return true;
      },
    } as RegisterOptions<ISetNewPasswordFields, 'reEnterPassword'>,
  };
};

export const getVariantByRole = (role?: string) => {
  switch (role) {
    case USER_TYPE.RECRUITER:
      return 'yellow';
    case SUB_ROLE.RECRUITMENT_MANAGER:
      return 'teal';
    case SUB_ROLE.ACCOUNT_OFFICER:
      return 'blue';
    default:
      return 'orange';
  }
};

export const snakeToTitleCase = (input?: string) => {
  if (!input) return;
  return input.replace(/[_-]/g, ' ');
};

export const removeSegmentsFromUrl = (
  navigate: NavigateFunction,
  locationPathname: string,
  segmentsToRemove: number = 1,
) => {
  const pathParts = locationPathname.split('/');
  if (pathParts.length > segmentsToRemove) {
    // Remove the specified number of segments
    const newPathParts = pathParts.slice(0, -segmentsToRemove);
    const newPath = newPathParts.join('/');
    navigate(newPath || '/', { replace: true });
  } else {
    navigate('/', { replace: true });
  }
};

export const createInitialFilterState = (
  fields: FilterField[],
): FilterOptions => {
  const initialState: FilterOptions = {};

  fields.forEach((field) => {
    initialState[field.name] = '';
  });

  return initialState;
};

export const formatCurrency = (number: number | null | undefined) => {
  if (number === null || number === undefined || isNaN(number)) return '₱ 0.00';
  if (number < 100_000) {
    return new Intl.NumberFormat('en-PH', {
      style: 'currency',
      currency: 'PHP',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    })
      .format(number)
      .replace('₱', '₱ ');
  } else if (number < 10_000_000) {
    return `₱ ${(number / 1_000_000).toFixed(1)}M`; // For 100K to less than 10M
  } else if (number < 1_000_000_000) {
    return `₱ ${Math.round(number / 1_000_000)}M`; // For 10M to less than 1B
  }
  return `₱ ${(number / 1_000_000_000).toFixed(1)}B`; // For 1B and above
};

type LabelOrValue<T> = T | { label: T } | { value: T };
// Enum to specify whether to get 'label' or 'value'
export enum EXTRACT_TYPE {
  LABEL = 'label',
  VALUE = 'value',
}
// Generic function to get either the label or value from an object
export const getLabelOrValue = <T>(
  value: LabelOrValue<T>,
  extractType: EXTRACT_TYPE,
): T => {
  if (typeof value === 'object' && value !== null) {
    switch (extractType) {
      case EXTRACT_TYPE.LABEL:
        if ('label' in value) {
          return (value as { label: T }).label;
        }
        break;
      case EXTRACT_TYPE.VALUE:
        if ('value' in value) {
          return (value as { value: T }).value;
        }
        break;
    }
  }
  return value as T;
};

export const getFileNameFromUrl = (url: string) => {
  try {
    const urlObj = new URL(url);
    const pathSegments = urlObj.pathname.split('/');
    return pathSegments.pop() || '-';
  } catch (error) {
    return '-';
  }
};

// For eleminating empty query params in get API
export const buildQueryParams = <T>(
  params: Record<string, T | undefined | null>,
): string => {
  const queryParams = new URLSearchParams();

  Object.entries(params).forEach(([key, value]) => {
    if (
      value !== undefined &&
      value !== null &&
      value !== '' &&
      (typeof value !== 'number' || (typeof value === 'number' && value > 0))
    ) {
      queryParams.append(key, value.toString());
    }
  });

  return queryParams.toString();
};

export const EnumToArray = <T extends EnumType<T>>(enumObj: T): string[] => {
  return Object.values(enumObj);
};

export const GetFormattedName = <T extends INameable>(data?: T): string => {
  if (!data) return '';
  const { firstName, lastName } = data;
  return `${firstName} ${lastName}`.trim();
};

export const arraysEqual = <T>(arr1: T[], arr2: T[]) => {
  if (arr1.length !== arr2.length) return false;
  return (
    arr1.every((value) => arr2.includes(value)) &&
    arr2.every((value) => arr1.includes(value))
  );
};

export const getRandomColorsFromArray = (
  totalColorsNeeded: number,
  availableColors: string[],
) => {
  const selectedColors: string[] = [];
  for (let i = 0; i < totalColorsNeeded; i++) {
    let newColorIndex: number;
    do {
      newColorIndex = Math.floor(Math.random() * availableColors.length);
    } while (i > 0 && selectedColors[i - 1] === availableColors[newColorIndex]);

    selectedColors.push(availableColors[newColorIndex] || '');
  }
  return selectedColors;
};

export const capitalizeWord = (text?: string) => {
  if (!text) return;
  return text
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const getMimeTypes = (extensions: string[] | string): string[] => {
  if (Array.isArray(extensions)) {
    return extensions.map((ext) => mimeTypes[ext] || '');
  }
  return [mimeTypes[extensions] || ''];
};

export const hasValidFilters = <T extends Record<string, unknown>>(
  filters: T,
): boolean => {
  return Object.keys(filters).some((key) => {
    const value = filters[key];
    // Check if the value is undefined or null
    if (value === undefined || value === null) {
      return false;
    }
    // Check if the value is a number and greater than 0
    if (typeof value === 'number') {
      return value > 0;
    }
    // Check if the value is a string and not empty after trimming
    if (typeof value === 'string') {
      return value.trim() !== '';
    }
    // Check if the value is an object and has at least one property with a valid value
    if (typeof value === 'object' && value !== null) {
      return Object.values(value).some((objValue) => {
        // Check if the inner value is a string and not empty after trimming
        if (typeof objValue === 'string') {
          return objValue.trim() !== '';
        }
        // Check if the inner value is a number and greater than 0
        if (typeof objValue === 'number') {
          return objValue > 0;
        }
        // Other types can be considered valid or not as needed
        return !!objValue; // Return true if objValue is a truthy value (excluding null and undefined)
      });
    }
    return false; // Default case if none of the conditions match
  });
};

export const getTenure = (startDate?: string, endDate?: string): string => {
  if (!startDate) {
    return '-';
  }

  const createdDate = new Date(startDate);
  const currentDate = endDate ? new Date(endDate) : new Date();

  const diffInMilliseconds = currentDate.getTime() - createdDate.getTime();
  const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));
  const diffInHours = Math.floor(diffInMilliseconds / (1000 * 60 * 60));
  const diffInDays = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24));
  const years = Math.floor(diffInDays / 365);
  const months = Math.floor((diffInDays % 365) / 30); // Approximation of months
  const days = diffInDays % 30;

  const format = (value: number, unit: string) =>
    `${value} ${unit}${value === 1 ? '' : 's'}`;

  if (years > 0) {
    return `${format(years, 'Year')}${months > 0 ? ` ${format(months, 'Month')}` : ''}`;
  }
  if (months > 0) {
    return `${format(months, 'Month')}${days > 0 ? ` ${format(days, 'Day')}` : ''}`;
  }
  if (days > 0) {
    return format(days, 'Day');
  }
  if (diffInHours > 0) {
    return format(diffInHours, 'Hour');
  }
  return format(diffInMinutes, 'Minute');
};

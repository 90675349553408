/* eslint-disable max-lines */
import React, { useEffect } from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  UseFormResetField,
  UseFormWatch,
} from 'react-hook-form';

import { FieldType } from '@/@types';
import {
  ICandidateTag,
  IPersonnelActionFormData,
  ITag,
} from '@/@types/candidate';
import DatePickerField from '@/components/DatePickerField';
import FormField from '@/components/FormField';
import RadioButtonGroup from '@/components/RadioButtonGroup';
import { Typography } from '@/components/Typography';
import { INPUT_ERRORS } from '@/constants';
import {
  ALLOWANCE,
  ALLOWANCE_DETAILS,
  DAILY_RATE_OPTIONS,
  HIRE_TYPE,
  PAN_FIELDS,
  RATE,
} from '@/constants/candidate';
import { strings } from '@/locales';
import { capitalizeWord, EnumToArray, GetFormattedName } from '@/utils/common';

import PersonnelActionHistory from './PersonnelActionFormHistorySection';

interface IPersonnelActionForm {
  control: Control<IPersonnelActionFormData>;
  watch: UseFormWatch<IPersonnelActionFormData>;
  error: FieldErrors<IPersonnelActionFormData>;
  resetField: UseFormResetField<IPersonnelActionFormData>;
  data?: ITag | ICandidateTag;
}

const PersonnelActionForm: React.FC<IPersonnelActionForm> = ({
  control,
  watch,
  error,
  resetField,
  data,
}) => {
  const staticText = strings.candidateScreen.personnelActionForm;

  const rateType = watch('rateType');
  const dailyRate = watch('dailyRate');

  useEffect(() => {
    if (watch('dailyRate')) {
      resetField('dailyRate');
    }
  }, [rateType]);

  useEffect(() => {
    if (watch('otherDailyRate')) {
      resetField('otherDailyRate', { keepError: false });
    }
  }, [dailyRate]);

  return (
    <div>
      {data?.isPanForm ? (
        <PersonnelActionHistory
          candidateId={
            (typeof data?.candidate === 'object'
              ? (data?.candidate as { _id: string })?._id
              : data?.candidate) || ''
          }
          ticketId={data?.ticket as string}
          info={{
            ticketRate:
              (data as ITag)?.ticketRate ||
              (data as ICandidateTag)?.ticket?.rate ||
              0,
            author: GetFormattedName(
              (data as ITag)?.author || (data as ICandidateTag)?.ticket?.author,
            ),
            typeOfEmployment:
              (data as ITag)?.typeOfEmployment ||
              (data as ICandidateTag)?.ticket?.typeOfEmployment,
          }}
          inFormView
          job={
            (data as ITag)?.jobPosition ||
            (data as ICandidateTag)?.ticket?.jobTitle
          }
          mrfNumber={String(
            (data as ITag)?.ticketNumber ||
              (data as ICandidateTag)?.ticket?.ticketNumber ||
              0,
          )}
        />
      ) : (
        <>
          <div className='xs:grid xs:grid-cols-2 gap-4 space-y-4 xs:space-y-0'>
            {PAN_FIELDS(watch('startDate'), watch('endDate'))?.map(
              (field, index) => (
                <div key={`field-${index}`}>
                  <Typography className='font-extrabold md:text-[15px]'>
                    {staticText[field.name as keyof typeof staticText]}
                    <span className='text-redColor text-xl ml-1'>*</span>
                  </Typography>
                  <Controller
                    control={control}
                    name={field.name as keyof IPersonnelActionFormData}
                    rules={{
                      required: INPUT_ERRORS.REQUIRED.required,
                      ...field.rules,
                    }}
                    render={({ field: { onChange, value, name } }) => {
                      switch (field.inputType) {
                        case FieldType.DatePicker: {
                          return (
                            <DatePickerField
                              value={value as Date}
                              setValue={onChange}
                              contanierClassName='w-full rounded-md h-10'
                              fromDate={
                                name === 'startDate'
                                  ? new Date()
                                  : (watch('startDate') as Date)
                              }
                              name={name}
                              errors={error}
                            />
                          );
                        }
                        default:
                          return (
                            <FormField
                              {...field}
                              className='w-full'
                              labelClassName='md:text-[8px]'
                              onChange={onChange}
                              errors={error}
                              value={value as string}
                            />
                          );
                      }
                    }}
                  />
                </div>
              ),
            )}
          </div>
          <div className='xs:grid xs:grid-cols-2 gap-4 space-y-4 xs:space-y-0 my-4'>
            <div>
              <Typography className='font-extrabold md:text-[15px]'>
                {staticText.hireType}
                <span className='text-redColor text-xl ml-1'>*</span>
              </Typography>
              <Controller
                control={control}
                name='hireType'
                rules={{ required: INPUT_ERRORS.REQUIRED.required }}
                render={({ field: { onChange, value, name } }) => {
                  return (
                    <RadioButtonGroup
                      name={name}
                      options={EnumToArray(HIRE_TYPE)}
                      errors={error}
                      value={value}
                      onChange={onChange}
                      containerClassName='whitespace-nowrap flex h-fit flex-col gap-2 mt-1'
                    />
                  );
                }}
              />
            </div>
            <div>
              <Typography className='font-extrabold md:text-[15px]'>
                {staticText.allowance}
                <span className='text-redColor text-xl ml-1'>*</span>
              </Typography>
              <Controller
                control={control}
                name='allowance'
                rules={{ required: INPUT_ERRORS.REQUIRED.required }}
                render={({ field: { onChange, value, name } }) => {
                  return (
                    <RadioButtonGroup
                      name={name}
                      options={EnumToArray(ALLOWANCE)}
                      errors={error}
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                        EnumToArray(ALLOWANCE_DETAILS).forEach((field) =>
                          resetField(field as keyof IPersonnelActionFormData, {
                            keepError: false,
                          }),
                        );
                      }}
                      containerClassName='whitespace-nowrap flex h-fit flex-col gap-2 mt-1'
                      contentClassName='flex w-full gap-2'
                      conditionalContent={(option) => {
                        const detailsFields =
                          (option === ALLOWANCE.FIXED &&
                            watch('allowance') === ALLOWANCE.FIXED) ||
                          (option === ALLOWANCE.PRORATED &&
                            watch('allowance') === ALLOWANCE.PRORATED);
                        return (
                          <>
                            {detailsFields ? (
                              <div className='flex flex-col gap-1 mt-2'>
                                {EnumToArray(ALLOWANCE_DETAILS).map((item) => {
                                  return (
                                    <Controller
                                      key={item}
                                      control={control}
                                      name={
                                        item as keyof IPersonnelActionFormData
                                      }
                                      rules={
                                        detailsFields
                                          ? {
                                              required:
                                                INPUT_ERRORS.REQUIRED.required,
                                              min: {
                                                value: 1,
                                                message:
                                                  'Value must be greater than 0',
                                              },
                                            }
                                          : {}
                                      }
                                      render={({
                                        field: { name, value, onChange },
                                      }) => (
                                        <div className='flex gap-2 my-0.5'>
                                          <Typography className='h-4 md:text-sm'>
                                            {capitalizeWord(
                                              item?.includes('Php')
                                                ? item?.replace('Php', ' Php')
                                                : 'Others',
                                            )}
                                          </Typography>
                                          <FormField
                                            name={name}
                                            className='h-4 border-0 border-b rounded-none border-quickSilver/50 px-1'
                                            onChange={onChange}
                                            errors={error}
                                            value={value as number}
                                            type='number'
                                            isRequired
                                          />
                                        </div>
                                      )}
                                    />
                                  );
                                })}
                              </div>
                            ) : null}
                          </>
                        );
                      }}
                    />
                  );
                }}
              />
            </div>
          </div>
          <div>
            <Typography className='font-extrabold md:text-[15px]'>
              {staticText.rateType}
              <span className='text-redColor text-xl ml-1'>*</span>
            </Typography>
            <Controller
              control={control}
              name='rateType'
              rules={{ required: INPUT_ERRORS.REQUIRED.required }}
              render={({ field: { onChange, value, name } }) => {
                return (
                  <RadioButtonGroup
                    name={name}
                    options={EnumToArray(RATE)}
                    errors={error}
                    value={value}
                    onChange={onChange}
                    containerClassName='whitespace-nowrap flex h-fit flex-col gap-2 mt-1'
                    conditionalContent={(option) => {
                      const isDailyRateSelected =
                        option === RATE.DAILY_RATE &&
                        watch('rateType') === RATE.DAILY_RATE;
                      return isDailyRateSelected ? (
                        <Controller
                          control={control}
                          name='dailyRate'
                          rules={
                            isDailyRateSelected
                              ? {
                                  required: INPUT_ERRORS.REQUIRED.required,
                                }
                              : {}
                          }
                          render={({ field: { name, value, onChange } }) => (
                            <RadioButtonGroup
                              errors={error}
                              name={name}
                              value={value!}
                              onChange={onChange}
                              options={EnumToArray(DAILY_RATE_OPTIONS)}
                              containerClassName='pl-10 flex flex-col gap-4 h-full mt-2'
                              contentClassName='flex w-full gap-2 justify-start'
                              conditionalContent={(option) => {
                                const isOtherDailyRateSelected =
                                  option === DAILY_RATE_OPTIONS.OTHER &&
                                  watch('dailyRate') ===
                                    DAILY_RATE_OPTIONS.OTHER;
                                return isOtherDailyRateSelected ? (
                                  <Controller
                                    control={control}
                                    name='otherDailyRate'
                                    rules={
                                      isOtherDailyRateSelected
                                        ? {
                                            required:
                                              INPUT_ERRORS.REQUIRED.required,
                                            min: {
                                              value: 1,
                                              message:
                                                'Value must be greater than 0',
                                            },
                                          }
                                        : {}
                                    }
                                    render={({
                                      field: { name, value, onChange },
                                    }) => (
                                      <div className='flex gap-2'>
                                        <FormField
                                          name={name}
                                          className='h-4 border-0 border-b rounded-none border-quickSilver/50 px-1'
                                          onChange={onChange}
                                          errors={error}
                                          value={value}
                                          type='number'
                                        />
                                        <Typography className='h-4'>
                                          days
                                        </Typography>
                                      </div>
                                    )}
                                  />
                                ) : null;
                              }}
                            />
                          )}
                        />
                      ) : null;
                    }}
                  />
                );
              }}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default PersonnelActionForm;

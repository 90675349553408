/* eslint-disable max-lines */
import React, { useCallback, useState } from 'react';
import { IoFilter } from 'react-icons/io5';
import { MdAccessTime } from 'react-icons/md';
import Picker from 'react-month-picker';

import { INameable } from '@/@types';
import { IDashboardManpowerSectionCompany } from '@/@types/dashboard';
import Container from '@/components/Container';
import StateIndicator from '@/components/StateIndicator';
import TooltipComponent from '@/components/Tooltip';
import { Typography } from '@/components/Typography';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion';
import { Progress } from '@/components/ui/progress';
import { MONTHS } from '@/constants';
import {
  COLORS_FOR_MANPOWER_SECTION,
  DASHBOARD_MANPOWER_STATIC_FORM_DATA_FOR_MODAL,
  DASHBOARD_MANPOWER_STATIC_FORM_MANAGER_DATA_FOR_MODAL,
  tooltipData,
} from '@/constants/dashboard';
import { useGenericQuery } from '@/hooks/useQueryData';
import { cn } from '@/lib/utils';
import { strings } from '@/locales';
import { useAuth } from '@/provider/AuthProvider';
import { useSidebarContext } from '@/provider/SidebarProvider';
import {
  fetchManpowerSectionDataForManager,
  IManpowerSectionDataForManager,
  IRecruiter,
  manpowerRequestFormDdashboard,
} from '@/services/dashboard';
import {
  GetFormattedName,
  getRandomColorsFromArray,
  getTenure,
} from '@/utils/common';
import { getLocalDate } from '@/utils/dateTime';

import ManpowerLoadingSkeleton from './ManpowerLoadingSkeleton';
import ManpowerSectionDetailItem from './ManpowerSectionDetailItem';

import 'react-month-picker/css/month-picker.css';

interface MonthPickerRef {
  show: () => void;
  dismiss: () => void;
}
interface IManpowerRequestFormSection {
  cardDisplay?: boolean;
  haveCharts?: boolean;
}

const ManpowerRequestFormSection: React.FC<IManpowerRequestFormSection> = ({
  cardDisplay,
  haveCharts,
}) => {
  const { dashboard: staticText, common } = strings;
  const [openItems, setOpenItems] = useState<string | undefined>(undefined);
  const { sidebarExpand } = useSidebarContext();
  const { isRecruitmentManager, isRecruiter } = useAuth();
  let pickerInstance: MonthPickerRef | null = null;
  const now = new Date();
  const [filterValue, setFilterValue] = useState({
    year: now.getFullYear(),
    month: now.getMonth() + 1,
  });

  const {
    data: manpowerRequestFormData,
    isLoading: manpowerRequestFormDataLoading,
  } = useGenericQuery(
    ['manpowerRequestFormDdashboard'],
    manpowerRequestFormDdashboard,
    { enabled: isRecruiter },
  );

  const { data, isLoading: manpowerRequestFormDataForManagerLoading } =
    useGenericQuery(
      ['fetchManpowerSectionDataForManager', filterValue],
      () =>
        fetchManpowerSectionDataForManager({
          month: MONTHS[filterValue.month - 1] || '',
          year: String(filterValue.year),
        }),
      { enabled: isRecruitmentManager },
    );

  const manpowerRequestFormDataForManager =
    data as IManpowerSectionDataForManager;

  const handleAccordionChange = useCallback(
    (value?: string) => setOpenItems(value),
    [],
  );

  const formatNumber = (num?: number): string | number => {
    if (!num) {
      return '00';
    } else if (num < 10) {
      return `0${num}`;
    }
    return num;
  };

  const displayedData = cardDisplay
    ? DASHBOARD_MANPOWER_STATIC_FORM_DATA_FOR_MODAL
    : manpowerRequestFormData;

  const displayedDataForRecruiter = cardDisplay
    ? DASHBOARD_MANPOWER_STATIC_FORM_MANAGER_DATA_FOR_MODAL
    : (manpowerRequestFormDataForManager as IManpowerSectionDataForManager)
        ?.recruiters;

  const totalVacancies = useCallback(() => {
    return Array.isArray(manpowerRequestFormData)
      ? manpowerRequestFormData.reduce(
          (sum, ticket) => sum + (ticket.totalVacancies || 0),
          0,
        )
      : 0;
  }, [manpowerRequestFormData]);

  const totalActiveReq = useCallback(() => {
    return Array.isArray(manpowerRequestFormData)
      ? manpowerRequestFormData.reduce(
          (sum, ticket) => sum + (ticket.activeRecruiter || 0),
          0,
        )
      : 0;
  }, [manpowerRequestFormData]);

  const totalNoOfPosition = useCallback(() => {
    return Array.isArray(manpowerRequestFormData)
      ? manpowerRequestFormData.reduce(
          (sum, item) => sum + (item.numberOfPositions || 0),
          0,
        )
      : 0;
  }, [manpowerRequestFormData]);

  const getDefaultValue = (
    index: number,
    itemId: string,
  ): string | undefined => {
    if (index === 0 && isRecruiter) return itemId;
    if (index === 0 && isRecruitmentManager) return String(index);
    return '';
  };
  const toolTipSectionData = tooltipData(
    totalVacancies(),
    totalActiveReq(),
    totalNoOfPosition(),
    manpowerRequestFormDataForManager?.totalEndorsedSum,
    manpowerRequestFormDataForManager?.totalOnProcessSum,
    manpowerRequestFormDataForManager?.totalPlacementSum,
    manpowerRequestFormDataForManager?.totalNotQualifiedSum,
    isRecruitmentManager,
  );

  const handleAMonthChange = (year: number, month: number) => {
    setFilterValue({ year, month });
    pickerInstance?.dismiss();
  };

  const makeText = (m: { year?: number; month?: number }) => {
    return m?.year && m?.month ? `${MONTHS[m.month - 1]} ${m.year}` : '?';
  };

  return (
    <Container
      className={cn({
        'p-0 md:p-0 border-none rounded-lg pointer-events-none select-none':
          cardDisplay,
      })}
    >
      <div className='grid grid-rows-[auto_1fr] h-full'>
        <div>
          <div className='flex flex-col xl:flex-row justify-between items-center gap-3 relative'>
            {cardDisplay ? null : (
              <Typography className='font-bold'>
                {staticText.manpowerFormSection}
              </Typography>
            )}
            {isRecruitmentManager && !cardDisplay ? (
              <div className=' px-2 py-0.5 cursor-pointer border rounded-md min-w-36 text-center bg-whitesmoke'>
                <Picker
                  ref={(ref: MonthPickerRef | null) => (pickerInstance = ref)}
                  years={{
                    min: { year: 1900, month: 1 },
                    max: {
                      year: new Date().getFullYear(),
                      month: new Date().getMonth() + 1,
                    },
                  }}
                  value={filterValue}
                  lang={MONTHS}
                  theme='light'
                  onChange={handleAMonthChange}
                />
                <div
                  onClick={() => pickerInstance?.show()}
                  className='cursor-pointer text-mouseGrey flex items-center justify-between gap-1'
                >
                  <label className='text-sm'>{makeText(filterValue)}</label>
                  <IoFilter size={14} />
                </div>
              </div>
            ) : null}
          </div>
          <div
            className={cn(
              'mb-3 grid grid-cols-3 lg:grid-cols-1 xl:grid-cols-3 gap-2 mt-3 px-1',
              {
                'mb-2 space-y-0 grid lg:grid-cols-3 ': cardDisplay,
                'grid grid-cols-2 space-y-0 lg:grid-cols-1 xl:grid-cols-2':
                  isRecruitmentManager,
                'grid lg:grid-cols-2': isRecruitmentManager && cardDisplay,
              },
            )}
          >
            {toolTipSectionData?.map(({ text, label, value }) => (
              <TooltipComponent
                key={label}
                text={text}
                align='center'
                className='capitalize md:text-xs text-mouseGrey max-w-52'
                directionalArrow
                showTooltip={isRecruitmentManager ? false : true}
                trigger={
                  <ManpowerSectionDetailItem
                    label={`${label}${isRecruitmentManager ? ':' : ''}`}
                    value={value || '0'}
                    loading={
                      manpowerRequestFormDataLoading ||
                      manpowerRequestFormDataForManagerLoading
                    }
                    labelClassName={cn(
                      'text-primary text-[9px] sm:text-[9px] md:text-[13px]',
                      {
                        'text-[6px] sm:text-[6px] md:text-[7px]': cardDisplay,
                        'text-[6px]  sm:text-[6px] md:text-[8px]':
                          cardDisplay && isRecruitmentManager,
                      },
                    )}
                    valueClassName={cn(
                      'text-primary font-bold text-[10px] sm:text-[10px] md:text-[13px]',
                      {
                        'text-[6px] sm:text-[6px] md:text-[8px]': cardDisplay,
                      },
                    )}
                    cardDisplay={cardDisplay}
                    containerClassName={cn(
                      '!bg-trafficWhite rounded-md text-center items-center w-full p-1.5 gap-1 shadow-sm justify-center space-y-0',
                      {
                        '!p-0 !gap-0': cardDisplay,
                        '!h-4 rounded-sm': isRecruitmentManager && cardDisplay,
                        'flex-row cursor-default items-center':
                          isRecruitmentManager,
                      },
                    )}
                    totals={isRecruitmentManager ? false : true}
                  />
                }
              />
            ))}
          </div>
        </div>
        <div
          className={cn('overflow-y-auto scrollbarHidden', {
            'overflow-hidden':
              !manpowerRequestFormData && !displayedDataForRecruiter,
          })}
        >
          <div
            className={cn('flex flex-col gap-3', {
              'gap-1': cardDisplay,
            })}
          >
            {/* loading section */}
            {(manpowerRequestFormDataForManagerLoading ||
              manpowerRequestFormDataLoading) &&
            !cardDisplay ? (
              <ManpowerLoadingSkeleton
                isRecruitmentManager={isRecruitmentManager}
              />
            ) : null}
            {/* No data section */}
            {((displayedData as IDashboardManpowerSectionCompany[])?.length ===
              0 &&
              isRecruiter &&
              !manpowerRequestFormDataLoading) ||
            (!displayedDataForRecruiter &&
              isRecruitmentManager &&
              !manpowerRequestFormDataForManagerLoading) ? (
              <StateIndicator
                state='Empty'
                noDataMessage={common.noDataAvailable}
                className='h-40'
              />
            ) : (
              <>
                {/* data section */}
                {(isRecruiter
                  ? (displayedData as IDashboardManpowerSectionCompany[])
                  : displayedDataForRecruiter || []
                )?.map((item, index) => {
                  const ticketColors = isRecruiter
                    ? getRandomColorsFromArray(
                        (item as IDashboardManpowerSectionCompany).tickets
                          ?.length || 0,
                        COLORS_FOR_MANPOWER_SECTION,
                      )
                    : null;
                  return (
                    <Container
                      className={cn(
                        'bg-trafficWhite border-none md:px-1.5 md:py-2 px-1.5 py-2 ',
                        {
                          'p-1 md:p-1 rounded-lg': cardDisplay,
                        },
                      )}
                      key={
                        isRecruitmentManager
                          ? `${index}:${(item as IRecruiter).firstName}`
                          : item._id
                      }
                    >
                      <Accordion
                        type='single'
                        collapsible
                        value={openItems}
                        onValueChange={handleAccordionChange}
                        defaultValue={getDefaultValue(index, item._id)}
                      >
                        <AccordionItem
                          value={
                            isRecruitmentManager ? String(index) : item._id
                          }
                          className='border-b-0'
                        >
                          <AccordionTrigger
                            className={cn(
                              'font-bold text-sm px-3 py-2 hover:no-underline text-start bg-lightSky rounded-xl ',
                              {
                                'px-1.5 md:px-1.5 py-0 md:py-0 rounded-lg':
                                  cardDisplay,
                              },
                            )}
                          >
                            <div
                              className={cn(
                                'w-full grid xs:grid-cols-5 gap-1',
                                {
                                  'lg:flex-col xl:flex-row': !sidebarExpand,
                                },
                              )}
                            >
                              <Typography
                                className={cn(
                                  'text-xs md:text-sm col-span-5 xs:col-span-2 xxl:col-span-2 font-bold w-full capitalize truncate',
                                  {
                                    'xs:col-span-5 xl:col-span-5':
                                      isRecruitmentManager,
                                    ' col-span-5 lg:col-span-5 xl:col-span-5':
                                      sidebarExpand && isRecruitmentManager,
                                    'text-[7px] md:text-[7px]': cardDisplay,
                                    'lg:col-span-2': sidebarExpand,
                                    'lg:col-span-5': haveCharts,
                                  },
                                )}
                              >
                                {isRecruiter ? (
                                  <>
                                    {(item as IDashboardManpowerSectionCompany)
                                      ?.companyName.length > 30
                                      ? `${(
                                          item as IDashboardManpowerSectionCompany
                                        )?.companyName.substring(0, 30)}...`
                                      : (
                                          item as IDashboardManpowerSectionCompany
                                        )?.companyName}
                                  </>
                                ) : (
                                  GetFormattedName(item as INameable)
                                )}
                              </Typography>
                              {isRecruiter ? (
                                <div
                                  className={cn(
                                    'col-span-5 xs:col-span-3 xxl:col-span-3 gap-1 w-full pr-1 grid grid-cols-2',
                                    {
                                      'xl:col-span-5': isRecruitmentManager,
                                      'gap-1': cardDisplay,
                                      'lg:col-span-3 lg:grid-cols-1 xl:grid-cols-2 xl:col-span-5 xxl:col-span-3':
                                        sidebarExpand && isRecruitmentManager,
                                      'lg:col-span-3': sidebarExpand,
                                      'lg:col-span-5 ': haveCharts,
                                    },
                                  )}
                                >
                                  <ManpowerSectionDetailItem
                                    label={staticText.activeReq}
                                    value={formatNumber(
                                      (item as IDashboardManpowerSectionCompany)
                                        ?.activeRecruiter || 0,
                                    )}
                                    valueClassName='font-bold '
                                    cardDisplay={cardDisplay}
                                  />
                                  <ManpowerSectionDetailItem
                                    label={staticText.noOfPosition}
                                    value={formatNumber(
                                      (item as IDashboardManpowerSectionCompany)
                                        ?.numberOfPositions || 0,
                                    )}
                                    valueClassName='font-bold'
                                    cardDisplay={cardDisplay}
                                  />
                                </div>
                              ) : null}
                            </div>
                          </AccordionTrigger>
                          <AccordionContent
                            className={cn('mt-3 pb-0 space-y-3', {
                              'mt-1': cardDisplay,
                            })}
                          >
                            {isRecruiter ? (
                              (
                                item as IDashboardManpowerSectionCompany
                              ).tickets?.map((ticket, ticketIndex) => (
                                <Container
                                  key={`${index}:${item._id}`}
                                  className={cn('p-3 md:p-3 ', {
                                    'p-1 md:p-1 rounded-lg gap-1': cardDisplay,
                                  })}
                                >
                                  <div
                                    className={cn(
                                      'flex justify-between gap-3 -mt-2 mb-1',
                                      {
                                        'lg:flex-col xl:flex-row pr-0.5 gap-3 lg:gap-0.5 xl:gap-3':
                                          sidebarExpand,
                                      },
                                    )}
                                  >
                                    <Typography className='text-[10px] md:text-xs flex items-center gap-0.5'>
                                      {staticText.postedOn}:{' '}
                                      {getLocalDate(ticket?.createdAt)}
                                    </Typography>
                                    <Typography className='text-[10px] md:text-xs flex items-center gap-0.5 justify-end'>
                                      <MdAccessTime />
                                      {getTenure(ticket?.createdAt)}{' '}
                                      {staticText.ago}
                                    </Typography>
                                  </div>
                                  <div
                                    className={cn(
                                      'grid grid-cols-2 gap-3 sm:grid-cols-4 lg:grid-cols-2 xl:grid-cols-4',
                                      {
                                        'lg:grid-cols-1 xl:grid-cols-4':
                                          sidebarExpand,
                                        'lg:grid-cols-4': !haveCharts,
                                      },
                                    )}
                                  >
                                    <ManpowerSectionDetailItem
                                      label={staticText.ticketNo}
                                      value={formatNumber(ticket.ticketNumber)}
                                      cardDisplay={cardDisplay}
                                    />
                                    <ManpowerSectionDetailItem
                                      label={staticText.jobPosition}
                                      value={ticket.jobPosition}
                                      cardDisplay={cardDisplay}
                                    />
                                    <ManpowerSectionDetailItem
                                      label={staticText.vacancies}
                                      value={`${ticket.candidatesHired || 0} / ${ticket.numberOfHC}`}
                                      cardDisplay={cardDisplay}
                                    />
                                    <div className='flex flex-col justify-center'>
                                      <Typography
                                        className={cn(
                                          'text-xs md:text-[13px] font-bold mb-1',
                                          {
                                            'text-[7px] md:text-[7px] mb-0':
                                              cardDisplay,
                                          },
                                        )}
                                      >
                                        {ticket.completePercentage}%
                                      </Typography>
                                      <Progress
                                        value={ticket.completePercentage}
                                        className={cn(
                                          'h-2 w-14 bg-opacity-20',
                                          cardDisplay
                                            ? 'bg-primary'
                                            : ticketColors?.[ticketIndex],
                                          { 'h-1 w-8': cardDisplay },
                                        )}
                                        fillColor={
                                          cardDisplay
                                            ? 'bg-primary'
                                            : ticketColors?.[ticketIndex]
                                        }
                                      />
                                    </div>
                                  </div>
                                </Container>
                              ))
                            ) : (
                              <Container
                                className={cn(
                                  'grid p-3 md:p-3 grid-cols-2 gap-0.5 sm:grid-cols-4 lg:grid-cols-2 xl:grid-cols-4',
                                  {
                                    'p-1 md:p-1 rounded-lg': cardDisplay,
                                    'lg:grid-cols-1 2xl:grid-cols-4':
                                      sidebarExpand,
                                    'lg:grid-cols-4': !haveCharts,
                                  },
                                )}
                                key={`${index}:${item._id}`}
                              >
                                <ManpowerSectionDetailItem
                                  label={staticText.onProcess}
                                  value={(item as IRecruiter).onProcess || '0'}
                                  cardDisplay={cardDisplay}
                                  valueClassName='text-center'
                                  labelClassName='text-center'
                                />
                                <ManpowerSectionDetailItem
                                  label={staticText.endorsed}
                                  value={(item as IRecruiter).endorsed || '0'}
                                  cardDisplay={cardDisplay}
                                  valueClassName='text-center'
                                  labelClassName='text-center'
                                />
                                <ManpowerSectionDetailItem
                                  label={staticText.placement}
                                  value={(item as IRecruiter).placement || '0'}
                                  cardDisplay={cardDisplay}
                                  valueClassName='text-center'
                                  labelClassName='text-center'
                                />
                                <ManpowerSectionDetailItem
                                  label={staticText.notQualified}
                                  value={
                                    (item as IRecruiter).notQualified || '0'
                                  }
                                  cardDisplay={cardDisplay}
                                  valueClassName='text-center'
                                  labelClassName='text-center'
                                />
                              </Container>
                            )}
                          </AccordionContent>
                        </AccordionItem>
                      </Accordion>
                    </Container>
                  );
                })}
              </>
            )}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ManpowerRequestFormSection;

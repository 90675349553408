import toast from 'react-hot-toast';

import {
  IDashboardManpowerSectionCompany,
  ISelectorDrawerFormValues,
  ISelectorDrawerFormValuesForRecManager,
  IStatisticValues,
} from '@/@types/dashboard';
import { API } from '@/api/ApiInstance';
import { REST_SUB_URL } from '@/constants/urls';
import { ISource } from '@/pages/Dashboard/components/SourcesKeyList';
import { buildQueryParams } from '@/utils/common';

interface IAllTicketsCountResponse {
  allTicketsCount: number;
}
export interface IMyTicketsCountResponse {
  myTicketsCount: number;
  completedHiringTicketsCount: number;
}
export interface ISalesResponse {
  totalSales: number;
  totalHiredCandidate: number;
}
export interface ICompantForHeadcount {
  clientName: string;
  companyId: string;
  totalHeadCount: string;
}
export interface ITotalHeadCountResponse {
  companies: {
    companyName: string;
    totalHeadCount: string;
    clients: ICompantForHeadcount[];
  }[];
  totalHeadCount: string;
}
export interface IOverallPerformanceResponse {
  overallPerformancePercentage: string;
  totalHeadCount: number;
  totalHiredCount: number;
}

export interface IRecruiter {
  _id: string;
  firstName?: string;
  lastName?: string;
  endorsed?: number;
  notQualified?: number;
  onProcess?: number;
  placement?: number;
  totalSales?: number;
  totalHired?: number;
  percentage?: string;
}
export interface IRecruiterListResponse {
  allTotalSales: number;
  allTotalHired: number;
  recruiters: IRecruiter[];
}
export interface IManpowerSectionDataForManager {
  totalEndorsedSum: number;
  totalNotQualifiedSum: number;
  totalOnProcessSum: number;
  totalPlacementSum: number;
  recruiters: IRecruiter[];
}

export interface ISourceResponse {
  platforms: ISource[];
  localRecruitmentActivities: ISource[];
}

export const fetchDashboardAllTicketsCount = async (): Promise<
  number | boolean
> => {
  const response = await API.Get<IAllTicketsCountResponse>(
    REST_SUB_URL.DASHBOARD_ALL_TICKETS_COUNTS,
  );
  if (response.status && response.data) {
    return response.data.allTicketsCount;
  }
  toast.error(response.message);
  return false;
};
export const fetchDashboardMyTicketsCount = async (): Promise<
  IMyTicketsCountResponse | boolean
> => {
  const response = await API.Get<IMyTicketsCountResponse>(
    REST_SUB_URL.DASHBOARD_MY_TICKETS_COUNTS,
  );
  if (response.status && response.data) {
    return response.data;
  }
  toast.error(response.message);
  return false;
};
export const fetchDashboardSales = async (): Promise<
  ISalesResponse | boolean
> => {
  const response = await API.Get<ISalesResponse>(
    REST_SUB_URL.DASHBOARD_TOTAL_HIRED_SALES,
  );
  if (response.status && response.data) {
    return response.data;
  }
  toast.error(response.message);
  return false;
};

export const fetchDashboardSource =
  async (): Promise<ISourceResponse | null> => {
    const response = await API.Get<ISourceResponse>(
      REST_SUB_URL.DASHBOARD_SOURCES,
    );
    if (response.status && response.data) {
      return response.data;
    }
    toast.error(response.message);
    return null;
  };

// This function is used for sending only required fileds in setting response
const cleanDashboardSettingsResponse = (
  data: ISelectorDrawerFormValues,
): ISelectorDrawerFormValues => {
  return {
    allTickets: data.allTickets,
    myTickets: data.myTickets,
    sales: data.sales,
    listOfLocalRecruitmentActivities: data.listOfLocalRecruitmentActivities,
    manpowerFormVisibility: data.manpowerFormVisibility,
    statistics: data.statistics,
    // statistics: {
    //   endorsed: data.statistics?.endorsed,
    //   onProcess: data.statistics?.onProcess,
    //   backedOut: data.statistics?.backedOut,
    //   placed: data.statistics?.placed,
    //   replacement: data.statistics?.replacement,
    //   onCall: data.statistics?.onCall,
    //   temporaryDeployed: data.statistics?.temporaryDeployed,
    // },
  };
};
const cleanRecManagerDashboardSettingsResponse = (
  data: ISelectorDrawerFormValuesForRecManager,
): ISelectorDrawerFormValuesForRecManager => {
  return {
    allHeadCount: data?.allHeadCount,
    overAllPerformance: data?.overAllPerformance,
    sales: data?.sales,
    recruitersPerformance: data?.recruitersPerformance,
    manpowerFormVisibility: data?.manpowerFormVisibility,
  };
};

export const fetchDashboardSettings =
  async (): Promise<ISelectorDrawerFormValues | null> => {
    const response = await API.Get<ISelectorDrawerFormValues>(
      REST_SUB_URL.DASHBOARD_SETTINGS,
    );
    if (response.status && response.data) {
      return cleanDashboardSettingsResponse(response.data);
    }
    toast.error(response.message);
    return null;
  };

export const fetchDashboardSettingsForRecManager =
  async (): Promise<ISelectorDrawerFormValuesForRecManager | null> => {
    const response = await API.Get<ISelectorDrawerFormValuesForRecManager>(
      REST_SUB_URL.DASHBOARD_SETTINGS_FOR_REC_MANAGER,
    );
    if (response.status && response.data) {
      return cleanRecManagerDashboardSettingsResponse(response.data);
    }
    toast.error(response.message);
    return null;
  };

export const updateDdashboardSettings = async (
  payload: ISelectorDrawerFormValues,
): Promise<ISelectorDrawerFormValues | boolean> => {
  const response = await API.Post<
    ISelectorDrawerFormValues,
    ISelectorDrawerFormValues
  >(REST_SUB_URL.DASHBOARD_SETTINGS, payload);
  if (response.status && response.data) {
    return cleanDashboardSettingsResponse(response.data);
  }
  toast.error(response.message);
  return false;
};

export const updateDdashboardRecManagerSettings = async (
  payload: ISelectorDrawerFormValuesForRecManager,
): Promise<ISelectorDrawerFormValuesForRecManager | boolean> => {
  const response = await API.Post<
    ISelectorDrawerFormValuesForRecManager,
    ISelectorDrawerFormValuesForRecManager
  >(REST_SUB_URL.DASHBOARD_SETTINGS_FOR_REC_MANAGER, payload);
  if (response.status && response.data) {
    return cleanRecManagerDashboardSettingsResponse(response.data);
  }
  toast.error(response.message);
  return false;
};

export const manpowerRequestFormDdashboard = async (): Promise<
  IDashboardManpowerSectionCompany | boolean
> => {
  const response = await API.Get<IDashboardManpowerSectionCompany>(
    REST_SUB_URL.DASHBOARD_MANPOWER_REQUEST_FORM,
  );
  if (response.status && response.data) {
    return response.data;
  }
  toast.error(response.message);
  return false;
};

export const dashboardStatistics = async (
  companyName: string,
): Promise<IStatisticValues[] | boolean> => {
  const response = await API.Get<IStatisticValues[]>(
    `${REST_SUB_URL.DASHBOARD_STATISTICS}/?companyName=${companyName}`,
  );
  if (response.status && response.data) {
    return response.data;
  }
  toast.error(response.message);
  return false;
};

export const fetchDashboardTotalSales = async (): Promise<
  IRecruiterListResponse | boolean
> => {
  const response = await API.Get<IRecruiterListResponse>(
    REST_SUB_URL.DASHBOARD_TOTAL_SALES,
  );
  if (response.status && response.data) {
    return response.data;
  }
  toast.error(response.message);
  return false;
};

export const fetchManpowerSectionDataForManager = async (params: {
  month: string;
  year: string;
}): Promise<IManpowerSectionDataForManager | boolean> => {
  const queryString = buildQueryParams({
    ...params,
  });
  const response = await API.Get<IManpowerSectionDataForManager>(
    `${REST_SUB_URL.DASHBOARD_MANPOWER_DATA_FOR_MANAGER}?${queryString}`,
  );
  if (response.status && response.data) {
    return response.data;
  }
  toast.error(response.message);
  return false;
};

export const fetchDashboardTotalHeadcounts = async (): Promise<
  ITotalHeadCountResponse | boolean
> => {
  const response = await API.Get<ITotalHeadCountResponse>(
    REST_SUB_URL.TOTAL_HEAD_COUNT,
  );
  if (response.status && response.data) {
    return response.data;
  }
  toast.error(response.message);
  return false;
};

export const fetchDashboardOverallPerformance = async (): Promise<
  IOverallPerformanceResponse | boolean
> => {
  const response = await API.Get<IOverallPerformanceResponse>(
    REST_SUB_URL.OVERALL_PERFORMANCE,
  );
  if (response.status && response.data) {
    return response.data;
  }
  toast.error(response.message);
  return false;
};

/* eslint-disable max-lines */
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, RegisterOptions, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import PhoneInput, { CountryData } from 'react-phone-input-2';

import { IEditUser, IUser } from '@/@types/auth';
import { IEmailNotificationPayload } from '@/@types/chat';
import Banner from '@/assets/images/banner.webp';
import Container from '@/components/Container';
import FileDropContainer from '@/components/FileDropContainer';
import FormField from '@/components/FormField';
import Modal from '@/components/Modal';
import NameAndDesc from '@/components/NameAndDesc';
import ProfileBadge from '@/components/ProfileBadge';
import StateIndicator from '@/components/StateIndicator';
import ToggleButton from '@/components/ToggleButton';
import { Typography } from '@/components/Typography';
import { Button } from '@/components/ui/button';
import { Separator } from '@/components/ui/separator';
import { Skeleton } from '@/components/ui/skeleton';
import { INPUT_ERRORS, USER_TYPE } from '@/constants';
import { useGenericMutation } from '@/hooks/useMutationData';
import { useGenericQuery } from '@/hooks/useQueryData';
import { cn } from '@/lib/utils';
import { strings } from '@/locales';
import { useAuth } from '@/provider/AuthProvider';
import { recruiterEmailNotification } from '@/services/chat';
import { fetchProfile, updateProfile } from '@/services/profile';
import {
  GetFormattedName,
  snakeToTitleCase,
  validateNewPasswordFields,
} from '@/utils/common';
import { ErrorMessage as HookFormErrorMessage } from '@hookform/error-message';

import 'react-phone-input-2/lib/style.css';

const Profile = () => {
  const { profileScreen: staticText, common } = strings;

  const { isAdmin, setUser, isCompanyClient, hasSubRole, user, isRecruiter } =
    useAuth();

  const [isEditing, setIsEditing] = useState(false);
  const [isPhoneValid, setPhoneValid] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [updatePassword, setUpdatePassword] = useState(false);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [currentProfile, setCurrentProfile] = useState<IEditUser>();
  const [
    recruiterEmailNotificationEnabled,
    setRecruiterEmailNotificationEnabled,
  ] = useState(!!user?.recruiterEmailNotificationEnabled);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const {
    control,
    handleSubmit,
    reset,
    clearErrors,
    trigger,
    formState: { errors, dirtyFields },
    setValue,
    watch,
  } = useForm<IEditUser>({
    defaultValues: currentProfile,
    mode: 'onChange',
  });
  const uploadedFile = watch('avatar');

  const { newPasswordRules, confirmNewPasswordRules } =
    validateNewPasswordFields(watch('newPassword'));

  const formFields = [
    {
      field: 'firstName',
      label: staticText.firstNameLabel,
      placeholder: staticText.firstNamePlaceholder,
      rules: { required: INPUT_ERRORS.FIRST_NAME.required },
    },
    {
      field: 'lastName',
      label: staticText.lasttNameLabel,
      placeholder: staticText.lastNamePlaceholder,
      rules: { required: INPUT_ERRORS.LAST_NAME.required },
    },
    {
      field: 'phoneNumber',
      label: staticText.phoneLabel,
      placeholder: staticText.phonePlaceholder,
      rules: {
        required: INPUT_ERRORS.PHONE_NUMBER.required,
        validate: (value: string) => {
          if (!dirtyFields.phoneNumber) return true;
          return value && isPhoneValid
            ? undefined
            : INPUT_ERRORS.INVALID_PHONE_NUMBER.required;
        },
      },
    },
    {
      field: 'email',
      label: staticText.emailLabel,
      placeholder: staticText.emailPlaceholder,
      disabled: true,
    },
    ...(isCompanyClient
      ? [
          {
            field: 'companyName',
            label: staticText.company,
            disabled: true,
          },
        ]
      : []),
    ...(isAdmin && !hasSubRole
      ? [
          ...(isEditing && updatePassword
            ? [
                {
                  field: 'password',
                  type: 'password',
                  label: staticText.oldPassword,
                  placeholder: staticText.oldPasswordPlaceholder,
                  hideInfoIcon: true,
                },
                {
                  field: 'newPassword',
                  type: 'password',
                  label: staticText.newPassword,
                  placeholder: staticText.newPassword,
                  rules: newPasswordRules,
                  disabled: !watch('password'),
                },
                {
                  field: 'confirmNewPassword',
                  type: 'password',
                  label: staticText.confirmPassword,
                  placeholder: staticText.confirmPassword,
                  rules: confirmNewPasswordRules,
                  hideInfoIcon: true,
                  disabled: !watch('password'),
                },
              ]
            : []),
        ]
      : []),
  ];

  const handlePasswordFileds = () => {
    setUpdatePassword(!updatePassword);
    setValue('password', '');
    setValue('newPassword', '');
    setValue('confirmNewPassword', '');
  };

  const handleCancel = () => {
    setUpdatePassword(!updatePassword);
    setIsEditing(false);
    reset();
  };

  const handleProgressUpdate = useCallback((percentage: number) => {
    setUploadProgress(percentage);
  }, []);

  const getDescriptionText = (
    field: string,
    currentProfile: IEditUser | null,
  ) => {
    if (!currentProfile) {
      return undefined;
    }
    return String(currentProfile[field as keyof IEditUser] || '');
  };

  const handleEditToggle = () => {
    setUploadProgress(0);
    setIsEditing(!isEditing);
    if (!isEditing) {
      if (currentProfile) {
        reset(currentProfile);
      }
    }
  };

  const { data, error, isLoading, refetch } = useGenericQuery(
    ['userProfile'],
    fetchProfile,
  );

  const updateProfileMutation = useGenericMutation<
    { payload: IEditUser; setPercent?: (percent: number) => void },
    IEditUser | boolean
  >(updateProfile, {
    onSuccess: (response) => {
      if (typeof response === 'object') {
        refetch();
        setCurrentProfile(response);
        toast.success(staticText.success);
        setUser(response);
        setIsEditing(false);
        setUpdatePassword(false);
      }
    },
  });

  const recruiterEmailNotificationMutation = useGenericMutation<
    IEmailNotificationPayload,
    boolean | IUser
  >(recruiterEmailNotification, {
    onSuccess: (response) => {
      if (typeof response === 'object') {
        setRecruiterEmailNotificationEnabled((prev) => !prev);
        setUser(response);
        setOpenConfirmationModal(false);
        toast.success(staticText.successMessage);
      }
    },
  });

  const handleEmailToggle = () => {
    setOpenConfirmationModal(true);
  };

  const handleEmailNotification = () => {
    recruiterEmailNotificationMutation.mutate({
      recruiterEmailNotificationEnabled: !recruiterEmailNotificationEnabled,
    });
  };

  const onSubmit = (data: IEditUser) => {
    updateProfileMutation.mutate({
      payload: data,
      setPercent:
        uploadedFile instanceof File ? handleProgressUpdate : undefined,
    });
  };

  useEffect(() => {
    if (typeof data === 'object') {
      setCurrentProfile(data);
    }
  }, [data]);

  useEffect(() => {
    if (currentProfile) {
      reset(currentProfile);
    }
  }, [currentProfile, reset]);

  useEffect(() => {
    if (watch('newPassword') && watch('confirmNewPassword')) {
      trigger('confirmNewPassword');
    }
  }, [watch('newPassword'), trigger]);

  useEffect(() => {
    if (uploadedFile && uploadedFile instanceof File && isEditing) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result as string);
      };
      reader.readAsDataURL(uploadedFile);
    } else {
      setPreviewUrl(null);
    }
  }, [uploadedFile]);

  if (error) return <StateIndicator state='Error' />;
  return (
    <>
      {openConfirmationModal ? (
        <Modal
          open={openConfirmationModal}
          handleCloseModal={() => setOpenConfirmationModal(false)}
          title={staticText.enableNotification}
          primaryBtnLabel={
            recruiterEmailNotificationEnabled ? common.disable : common.enable
          }
          primaryBtnAction={handleEmailNotification}
          buttonDisabled={recruiterEmailNotificationMutation.isPending}
          isLoading={recruiterEmailNotificationMutation.isPending}
        >
          <>
            <Typography className='block font-bold mb-1'>
              {staticText.emailConfirmation}
            </Typography>
          </>
        </Modal>
      ) : null}
      <div className='flex flex-col h-full'>
        <div className='flex-grow'>
          <Container className='relative w-full h-auto space-y-5'>
            <div>
              <Typography className='font-bold md:text-2xl mx-1'>
                {staticText.title}
              </Typography>
              <div
                className={cn(
                  'mt-3 relative pb-20 sm:pb-20 md:pb-28 border rounded-xl',
                  { 'pb-52 sm:pb-56 md:pb-60': isEditing },
                )}
              >
                <Container className='h-28 relative overflow-hidden mh-32 md:h-40 object-cover'>
                  {/* Video background */}
                  <video
                    autoPlay
                    loop
                    muted
                    className='absolute top-0 left-0 w-full h-full object-cover z-0'
                  >
                    <source
                      src={require('@/assets/images/heroSection.mp4')}
                      type='video/mp4'
                    />
                  </video>
                  <div className='absolute top-0 left-0 w-full h-full bg-blue-500 opacity-80 z-10 bg-gradient-to-r from-blueMoon via-hydra to-bracingBlue' />
                  <img
                    className='absolute top-0 left-0 w-full h-full '
                    src={Banner}
                  />
                </Container>
                <div className='absolute bottom-6 xs:bottom-3 sm:bottom-5 md:bottom-6 px-3 xs:px-5 md:px-10 w-full z-20'>
                  {isLoading ? (
                    <div className='flex gap-1 xs:gap-5 items-center'>
                      <Skeleton className='border-white rounded-full size-16 xs:size-24 md:size-32 border-[7px]' />
                      <div>
                        <Skeleton className='h-2.5 xs:h-3 md:h-4 w-36 md:w-44 mt-5' />
                        <Skeleton className='h-2.5 xs:h-3 md:h-4 w-20 md:w-24 mt-1 xs:mt-2' />
                      </div>
                    </div>
                  ) : (
                    <div>
                      <ProfileBadge
                        name={
                          GetFormattedName(currentProfile).length > 20 &&
                          screen.width < 768
                            ? `${GetFormattedName(currentProfile).substring(0, 20)}...`
                            : GetFormattedName(currentProfile)
                        }
                        profilePicture={
                          previewUrl
                            ? previewUrl
                            : (currentProfile?.avatar as string)
                        }
                        attribute={snakeToTitleCase(
                          (currentProfile?.profileType === USER_TYPE.ADMIN
                            ? currentProfile?.subRole
                            : currentProfile?.profileType
                          )?.toLowerCase(),
                        )}
                        className='flex items-center justify-start w-full overflow-hidden'
                        nameClassName='mt-2 sm:mt-6 text-base xs:text-lg md:text-xl'
                        attributeClassName='text-xs xs:text-sm xs:mt-0.5'
                        avatarClassName='size-16 xs:size-24 md:size-32 border-[7px] border-white bg-white'
                        isLoading={isLoading}
                      />
                    </div>
                  )}
                  {isEditing && (
                    <Controller
                      name='avatar'
                      control={control}
                      render={({ field: { onChange } }) => (
                        <FileDropContainer
                          setFile={onChange}
                          fileName={(uploadedFile as File)?.name}
                          buttonText={staticText.uploadProfileImage}
                          dropzoneClassName='w-full h-28 mt-3'
                          uploadingDivClassName='w-full h-28'
                          acceptedFileFormat={['.png', '.jpeg']}
                          progress={
                            uploadedFile instanceof File
                              ? uploadProgress
                              : undefined
                          }
                        />
                      )}
                    />
                  )}
                </div>
              </div>
            </div>
            <div>
              <Typography className='font-bold md:text-2xl mx-1'>
                {staticText.personalInfo}
              </Typography>
              <div className='border rounded-[7px] mt-4 p-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-4 justify-between w-full'>
                {formFields.map(
                  (
                    {
                      field,
                      label,
                      placeholder,
                      rules,
                      disabled,
                      type,
                      hideInfoIcon,
                    },
                    index,
                  ) => (
                    <React.Fragment key={label}>
                      {index ===
                      formFields.findIndex((f) =>
                        ['password'].includes(f.field),
                      ) ? (
                        <Separator className='bg-flashWhite col-span-1 md:col-span-2 lg:col-span-3 xl:col-span-4 my-4' />
                      ) : null}
                      <div className='w-full md:w-auto'>
                        {isEditing ? (
                          <Controller
                            name={field as keyof IEditUser}
                            control={control}
                            rules={
                              rules as RegisterOptions<
                                IEditUser,
                                keyof IEditUser
                              >
                            }
                            render={({ field: { onChange, value, name } }) =>
                              label === staticText.phoneLabel ? (
                                <div>
                                  <Typography className='flex capitalize font-bold md:text-base mt-2'>
                                    {label}
                                    <span className='text-redColor text-xl ml-1'>
                                      *
                                    </span>
                                  </Typography>
                                  <PhoneInput
                                    country={'ph'}
                                    enableSearch
                                    value={value as string}
                                    onChange={(
                                      value: string,
                                      country: CountryData,
                                      _e: React.ChangeEvent<HTMLInputElement> | null,
                                      formattedValue: string,
                                    ) => {
                                      const { format, dialCode } = country;
                                      const isValidPhone =
                                        format?.length ===
                                          formattedValue?.length &&
                                        (value.startsWith(dialCode) ||
                                          dialCode.startsWith(value));
                                      setPhoneValid(isValidPhone);
                                      setValue(name, value, {
                                        shouldValidate: true,
                                        shouldDirty: true,
                                      });
                                      trigger(name).then(() => {
                                        if (isValidPhone) {
                                          clearErrors(name);
                                        }
                                      });
                                    }}
                                    inputProps={{
                                      name: 'phone',
                                      required: true,
                                      autoFocus: true,
                                    }}
                                    inputClass='!h-12 text-base disabled:!bg-greyWhite !w-full !border-greyWhite'
                                    containerClass='h-12'
                                  />
                                  {errors && (
                                    <HookFormErrorMessage
                                      errors={errors}
                                      name={name}
                                      render={({ message }) => (
                                        <p className='text-redColor text-xs'>
                                          {message}
                                        </p>
                                      )}
                                    />
                                  )}
                                </div>
                              ) : (
                                <FormField
                                  title={label}
                                  placeholder={placeholder}
                                  name={field}
                                  value={value?.toString()}
                                  hideInfoIcon={hideInfoIcon}
                                  type={type}
                                  onChange={onChange}
                                  isRequired={!!rules?.required}
                                  errors={errors}
                                  disabled={disabled}
                                  labelClassName='font-bold md:text-base'
                                />
                              )
                            }
                          />
                        ) : (
                          <NameAndDesc
                            title={label}
                            description={`${label === staticText.phoneLabel && currentProfile?.phoneNumber ? '+' : ''}${getDescriptionText(
                              field,
                              currentProfile as IEditUser,
                            )}`}
                            isLoading={isLoading}
                          />
                        )}
                      </div>
                    </React.Fragment>
                  ),
                )}
              </div>
            </div>
            {isRecruiter ? (
              <div>
                <Typography className='font-bold md:text-2xl mx-1'>
                  {staticText.preferences}
                </Typography>
                <div className='border rounded-[7px] mt-4 p-4 w-full'>
                  <ToggleButton
                    title={staticText.enableNotification}
                    isChecked={recruiterEmailNotificationEnabled}
                    onClick={handleEmailToggle}
                  />
                </div>
              </div>
            ) : null}
            {isEditing && isAdmin && !hasSubRole ? (
              <Button
                onClick={handlePasswordFileds}
                variant='link'
                className='w-auto px-0 h-8'
              >
                {updatePassword
                  ? staticText.cancelUpdatePassword
                  : staticText.updatePassword}
              </Button>
            ) : null}
          </Container>
        </div>
        <div className='flex justify-end gap-4 p-4'>
          {isEditing && (
            <Button
              onClick={handleCancel}
              variant='outline'
              className='w-32 h-11'
              disabled={updateProfileMutation.isPending}
            >
              {staticText.cancel}
            </Button>
          )}
          <Button
            onClick={isEditing ? handleSubmit(onSubmit) : handleEditToggle}
            loading={updateProfileMutation.status === 'pending'}
            className='w-32 h-11'
            disabled={
              (isEditing && !Object.keys(dirtyFields).length) ||
              isLoading ||
              !data ||
              updateProfileMutation.isPending
            }
          >
            {isEditing ? staticText.save : staticText.edit}
          </Button>
        </div>
      </div>
    </>
  );
};

export default Profile;

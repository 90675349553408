import React from 'react';
import { IoMdInformationCircleOutline } from 'react-icons/io';

import CircularProgressBar from '@/components/CircularProgressBar';
import Container from '@/components/Container';
import TooltipComponent from '@/components/Tooltip';
import { Typography } from '@/components/Typography';
import { Skeleton } from '@/components/ui/skeleton';
import { strings } from '@/locales';
import { useAuth } from '@/provider/AuthProvider';

interface DashboardCardProps {
  keyId: number;
  title: string;
  count?: string;
  supportCount?: string;
  text: string;
  countUnit?: string;
  isLoading?: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<string>>;
}

const DashboardCard: React.FC<DashboardCardProps> = ({
  keyId,
  title,
  count = 0,
  supportCount = 0,
  text,
  countUnit,
  isLoading = false,
  setModalOpen,
}) => {
  const staticText = strings.dashboard;
  const showCircularProgressIndicator = keyId === 1;
  const { isRecruitmentManager } = useAuth();

  const handleCardCLick = () => {
    if (
      (title === staticText.totalHeadcount ||
        title === staticText.totalSales) &&
      !isLoading
    ) {
      setModalOpen(title);
    }
  };

  return (
    <div>
      <Container className='flex flex-col justify-between gap-y-3 bg-trafficWhite border-none rounded-lg shadow-md md:p-2 xl:p-5'>
        <div className='flex justify-between'>
          <Typography className='text-primaryBlack font-bold text-xl md:text-[26px]'>
            {title}
          </Typography>
          {(title === staticText.totalHeadcount ||
            title === staticText.totalSales) &&
          count ? (
            <IoMdInformationCircleOutline
              className=' size-4 mt-1 cursor-pointer'
              onClick={handleCardCLick}
            />
          ) : null}
        </div>
        {showCircularProgressIndicator ? (
          <div className='w-full flex flex-col justify-center items-center h-20'>
            <CircularProgressBar
              data={Number(supportCount)}
              total={Number(count)}
              isLoading={isLoading}
            />
          </div>
        ) : (
          <div className='flex items-baseline'>
            {isLoading ? (
              <Skeleton className='h-7 my-2 w-20' />
            ) : (
              <>
                <Typography
                  className='text-primary font-black md:text-[40px]'
                  variant='heading'
                >
                  {count || 0}
                </Typography>
                {countUnit ? (
                  <span className='ml-2 text-xs md:text-base text-primaryBlack font-bold'>
                    {countUnit}
                  </span>
                ) : null}
              </>
            )}
          </div>
        )}
        {title === staticText.sales && isLoading ? (
          <Skeleton className='w-20 h-5 inline-block' />
        ) : (
          <div className='flex gap-2'>
            {title !== staticText.myTickets ? (
              <Typography className='text-mouseGrey text-base md:text-lg font-bold'>
                {isRecruitmentManager ? '' : supportCount || ''}
                {text}
              </Typography>
            ) : null}
            {title === staticText.sales && !isRecruitmentManager ? (
              <TooltipComponent
                align='center'
                className='py-2 px-4 text-xs'
                triggerClassName='text-mouseGrey'
              >
                {strings.dashboard.perCandidatePHP}
              </TooltipComponent>
            ) : null}
          </div>
        )}
      </Container>
    </div>
  );
};

export default DashboardCard;

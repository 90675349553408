/* eslint-disable max-lines */
import React, { useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';

import { IDropdownOption, IPaginationResponse } from '@/@types';
import {
  FetchCandidateResponse,
  ICandidate,
  ICandidateTag,
  ICompaniesAndTicketsResponse,
  IEndorsementFormData,
  IPersonnelActionFormData,
  IRecruiterInterviewEvaluationFormData,
  ITag,
  ITagsPayload,
  ITagType,
} from '@/@types/candidate';
import { SendEndorsementPayload } from '@/@types/request';
import Modal from '@/components/Modal';
import { Typography } from '@/components/Typography';
import { INPUT_ERRORS } from '@/constants';
import {
  CANDIDATE_STATUS,
  initialEndorsementFormState,
  interviewEvaluationFormInitialValue,
  personnelActionFormDataInitialState,
} from '@/constants/candidate';
import { useGenericMutation } from '@/hooks/useMutationData';
import { useGenericQuery } from '@/hooks/useQueryData';
import { cn } from '@/lib/utils';
import { strings } from '@/locales';
import InterviewEvaluationHistory from '@/pages/TicketDetails/components/InterviewEvaluationHistory';
import { useCandidate } from '@/provider/CandidateProvider';
import {
  fetchCompanyAndTickets,
  submitPersonnelActionForm,
  submitRecruiterInterviewEvaluationForm,
  updateTagsDetail,
} from '@/services/candidate';
import { sendEndorsement } from '@/services/request';
import {
  createInitialFilterState,
  EXTRACT_TYPE,
  getLabelOrValue,
} from '@/utils/common';
import { QueryObserverResult, RefetchOptions } from '@tanstack/react-query';

import EditModalTagSection from './EditModalTagSection';
import EndorsementForm from './EndorsementForm';
import FormConstantDataSection from './FormConstantDataSection';
import PersonnelActionForm from './PersonnelActionForm';
import RecruiterInterviewEvaluationForm from './RecruiterInterviewEvaluationForm';

interface IEditModalProps {
  refetch?:
    | ((
        options?: RefetchOptions,
      ) => Promise<
        QueryObserverResult<FetchCandidateResponse | boolean, unknown>
      >)
    | ((
        options?: RefetchOptions,
      ) => Promise<QueryObserverResult<ICandidate | boolean, unknown>>)
    | ((
        options?: RefetchOptions | undefined,
      ) => Promise<
        QueryObserverResult<
          boolean | IPaginationResponse<ICandidateTag>,
          unknown
        >
      >);
  ticketData?: {
    ticketId?: string;
    ticketNumber?: number;
    department?: string;
    tagStatus?: CANDIDATE_STATUS;
    override?: boolean | null;
    _id?: string;
  };
  setRowForUpdateTag?: React.Dispatch<
    React.SetStateAction<ICandidateTag | null>
  >;
  rowForUpdateTag?: ICandidateTag | null;
}

const EditModal: React.FC<IEditModalProps> = ({
  refetch,
  ticketData,
  setRowForUpdateTag,
  rowForUpdateTag,
}) => {
  const { candidateScreen: staticText, ticketDetailScreen } = strings;

  const { editableCandidateData, openEditModal, handleCloseEditModal } =
    useCandidate();

  const [companyPage, setCompanyPage] = useState(0);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [selectedTag, setSelectedTag] = useState<ITagType | null>(null);

  const {
    control,
    reset,
    resetField,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<ITagType>({
    mode: 'all',
    defaultValues: createInitialFilterState([]),
  });

  const {
    control: panControl,
    handleSubmit: handleSubmitPanForm,
    reset: resetPanForm,
    watch: panWatch,
    setError,
    resetField: resetPanField,
    formState: { errors: panError, isDirty },
  } = useForm<IPersonnelActionFormData>({
    mode: 'all',
    defaultValues: personnelActionFormDataInitialState,
  });

  const {
    control: endorsementControl,
    watch: watchEndorsmentData,
    handleSubmit: handleSubmitEndorsement,
    reset: endorsementReset,
    formState: { errors: endorsementError },
  } = useForm<IEndorsementFormData>({
    mode: 'all',
    defaultValues: initialEndorsementFormState,
  });

  const {
    control: interviewEvaluationControl,
    handleSubmit: handleSubmitInterviewEvaluation,
    reset: interviewEvaluationFormReset,
    formState: { errors: interviewEvaluationError },
  } = useForm<IRecruiterInterviewEvaluationFormData>({
    mode: 'all',
    defaultValues: interviewEvaluationFormInitialValue,
  });

  const { data: dropDownData, isLoading } = useGenericQuery(
    ['ticket-with-companies', companyPage],
    () => fetchCompanyAndTickets(companyPage + 1),
  );

  const moreCompaniesAvailable = dropDownData
    ? companyPage + 1 <
      (dropDownData as IPaginationResponse<ICompaniesAndTicketsResponse>)
        ?.results?.length
    : false;

  const ticketValue = watch('ticket');
  const companyName = watch('companyName');
  const status = watch('status');

  const handleEditPayload = useMemo(
    () => ({
      ticket: getLabelOrValue(ticketValue, EXTRACT_TYPE.VALUE) as string,
      status,
    }),
    [ticketValue, status],
  );

  const tagData = useMemo(() => {
    return editableCandidateData?.tags?.find(
      (item) => item.ticket === (ticketValue as IDropdownOption)?.value,
    ) as ITag;
  }, [editableCandidateData, ticketValue]);

  const handleClosingModal = () => {
    reset();
    interviewEvaluationFormReset();
    endorsementReset();
    resetPanForm();
    handleCloseEditModal();
  };

  // simple tag update api function
  const updateTagsMutation = useGenericMutation<
    { id: string; payload: ITagsPayload },
    ITagsPayload | boolean
  >(({ id, payload }) => updateTagsDetail(id, payload), {
    onSuccess: (response) => {
      if (response) {
        refetch?.();
        toast.success(staticText.tagUpdateSuccess);
        handleClosingModal();
        setRowForUpdateTag?.(null);
      }
    },
  });
  const handleEdit: SubmitHandler<ITagType> = (formData) => {
    const payload = {
      ticket: getLabelOrValue(formData.ticket, EXTRACT_TYPE.VALUE) as string,
      tags:
        typeof formData.status === 'object'
          ? (getLabelOrValue(formData?.status, EXTRACT_TYPE.LABEL) as string)
          : (formData.status as string),
    };
    updateTagsMutation.mutate({
      id: editableCandidateData?._id || '0',
      payload,
    });
  };

  // personnel action form and update tag api function
  const submitPersonnelActionFormMutation = useGenericMutation<
    IPersonnelActionFormData,
    boolean
  >(submitPersonnelActionForm, {
    onSuccess: (response) => {
      if (response) {
        refetch?.();
        handleCloseEditModal();
      }
    },
  });
  const handleSubmitPersonnelActionForm: SubmitHandler<
    IPersonnelActionFormData
  > = (formData) => {
    const payload = {
      candidate: editableCandidateData?._id,
      ticket: (ticketValue as IDropdownOption).value,
      ...formData,
    };
    submitPersonnelActionFormMutation.mutate(payload);
  };

  const sendEndorsementMutation = useGenericMutation<
    {
      id: string;
      payload: SendEndorsementPayload;
      setPercent?: (percent: number) => void;
    },
    ICandidate | null | boolean
  >(sendEndorsement, {
    onSuccess: (response) => {
      if (response) {
        handleEdit(handleEditPayload);
      }
    },
  });

  const handleSendEndorsement = (data: IEndorsementFormData) => {
    const tagId = editableCandidateData?.tags?.find(
      (item) => item.ticket === (ticketValue as IDropdownOption).value,
    );
    const payload = {
      id: tagId?._id || String(ticketData?._id) || '',
      payload: {
        description: data.description,
        document: data.document as File,
        ticketId: (ticketValue as IDropdownOption).value,
      },
      setPercent: data?.document
        ? (percent: number) => {
            setUploadProgress(percent);
          }
        : undefined,
    };

    sendEndorsementMutation.mutate(payload);
  };

  const submitInterviewEvaluationFormMutation = useGenericMutation<
    IRecruiterInterviewEvaluationFormData,
    boolean
  >(submitRecruiterInterviewEvaluationForm, {
    onSuccess: (response) => {
      if (response) {
        handleEdit(handleEditPayload);
      }
    },
  });
  const handleSubmitInterviewEvaluationForm: SubmitHandler<
    IRecruiterInterviewEvaluationFormData
  > = (formData) => {
    const payload = {
      candidate: editableCandidateData?._id,
      ticket: (ticketValue as IDropdownOption).value,
      ...formData,
      dateOfAssessment: new Date().toISOString(),
    };
    submitInterviewEvaluationFormMutation.mutate(payload);
  };

  const statusHaveForms = useMemo(() => {
    return [
      CANDIDATE_STATUS.INTERVIEW_CLEARED,
      CANDIDATE_STATUS.HR_ORIENTATION,
      CANDIDATE_STATUS.ENDORSED,
    ].includes(status as CANDIDATE_STATUS);
  }, [status]);

  const btnName = useMemo(() => {
    return editableCandidateData?.tags?.some(
      (item) =>
        item.ticket === getLabelOrValue(ticketValue, EXTRACT_TYPE.VALUE),
    )
      ? strings.common.update
      : strings.common.add;
  }, [editableCandidateData?.tags, ticketValue]);

  function getFormTitle() {
    switch (status) {
      case CANDIDATE_STATUS.HR_ORIENTATION:
        return `${staticText.personnelActionForm.title} ${(tagData || rowForUpdateTag)?.isPanForm ? 'History' : ''}`;
      case CANDIDATE_STATUS.INTERVIEW_CLEARED:
        return `${ticketDetailScreen.interviewEvaluation.title} ${(tagData || rowForUpdateTag)?.isRecruiterIAF ? 'History' : ''}`;
      case CANDIDATE_STATUS.ENDORSED:
        return `${staticText.endorsedForm.title} ${(tagData || rowForUpdateTag)?.isEndorsed ? 'History' : ''}`;
      case CANDIDATE_STATUS.PRE_DEPLOY:
        return `Client's ${ticketDetailScreen.interviewEvaluation.title} History`;
      default:
        return '';
    }
  }
  const determineAction = () => {
    switch (status) {
      case CANDIDATE_STATUS.HR_ORIENTATION:
        return handleSubmitPanForm(handleSubmitPersonnelActionForm);
      case CANDIDATE_STATUS.ENDORSED:
        return handleSubmitEndorsement(handleSendEndorsement);
      case CANDIDATE_STATUS.INTERVIEW_CLEARED:
        return handleSubmitInterviewEvaluation(
          handleSubmitInterviewEvaluationForm,
        );
      default:
        return handleSubmit(handleEdit);
    }
  };

  const startDate = panWatch('startDate');
  const endDate = panWatch('endDate');
  useEffect(() => {
    if (startDate && endDate && new Date(endDate) < new Date(startDate)) {
      setError('endDate', {
        message: INPUT_ERRORS.END_DATE.validate,
      });
    }
  }, [startDate, endDate]);

  useEffect(() => {
    if (dropDownData) {
      const company = dropDownData?.results?.find(
        (company) => company?.name === companyName,
      );
      const companyHasTicket = company?.tickets?.some(
        (ticket) => ticket?._id === (ticketValue as IDropdownOption)?.value,
      );
      if (!companyHasTicket) {
        resetField('ticket');
      }
    }
  }, [companyName]);

  const { isCompanyClientIAF, isStatusHrOrientation } = useMemo(() => {
    const isCompanyClientIAF =
      status === CANDIDATE_STATUS.PRE_DEPLOY &&
      (tagData?.isCompanyClientIAF || rowForUpdateTag?.isCompanyClientIAF);

    const isStatusHrOrientation = tagData?.status || rowForUpdateTag?.status;

    return { isCompanyClientIAF, isStatusHrOrientation };
  }, [status, tagData, rowForUpdateTag]);

  return (
    <Modal
      open={openEditModal}
      handleCloseModal={handleClosingModal}
      primaryBtnLabel={btnName}
      primaryBtnAction={determineAction()}
      secondaryBtnLabel={staticText.cancel}
      buttonDisabled={
        updateTagsMutation.status === 'pending' ||
        sendEndorsementMutation.status === 'pending' ||
        submitPersonnelActionFormMutation.status === 'pending' ||
        submitInterviewEvaluationFormMutation.status === 'pending' ||
        (status === CANDIDATE_STATUS.HR_ORIENTATION && !isDirty) ||
        selectedTag?.status === watch('status') ||
        ticketData?.tagStatus === watch('status')
      }
      isLoading={
        updateTagsMutation.status === 'pending' ||
        submitPersonnelActionFormMutation.status === 'pending' ||
        submitInterviewEvaluationFormMutation.status === 'pending'
      }
      contentClassName={cn({
        'md:max-w-6xl': statusHaveForms || isCompanyClientIAF,
        'max-h-[80vh]': window.innerWidth < 768,
      })}
    >
      <div className='md:grid md:grid-cols-5 max-h-[calc(80vh-130px)] overflow-scroll'>
        <div
          className={cn('md:col-span-5 space-y-4', {
            'max-w-6xl': statusHaveForms || isCompanyClientIAF,
            'md:col-span-2': statusHaveForms || isCompanyClientIAF,
          })}
        >
          <Typography className='text-[22px] md:text-[22px] font-extrabold fixed top-6 w-full'>
            {staticText.edit}
          </Typography>
          <div className='overflow-y-scroll scrollbarHidden md:max-h-[60vh]'>
            <EditModalTagSection
              control={control}
              errors={errors}
              setValue={setValue}
              companyName={companyName}
              ticketValue={ticketValue}
              statusHaveForms={statusHaveForms || isCompanyClientIAF!}
              selectedCompany={companyName}
              ticketData={ticketData}
              setSelectedTag={setSelectedTag}
              watchStatus={status}
              dataList={dropDownData?.results}
              loadingDataList={isLoading}
              setCompanyPage={setCompanyPage}
              moreCompaniesAvailable={moreCompaniesAvailable}
              isStatusHrOrientation={
                isStatusHrOrientation === CANDIDATE_STATUS.HR_ORIENTATION
              }
            />
          </div>
        </div>
        {statusHaveForms || isCompanyClientIAF ? (
          <div className='md:col-span-3 md:flex'>
            <div className='my-5 md:my-0 md:mx-3 h-[1px] w-full md:w-[1px] md:h-full bg-greyWhite dark:bg-slate-800' />
            <div className='w-full md:overflow-scroll scrollbarHidden md:overflow-y-scroll scrollbarHidden md:max-h-[60vh]'>
              <Typography className='text-[22px] md:text-[22px] font-extrabold md:fixed w-full md:top-6'>
                {getFormTitle()}
              </Typography>
              {status === CANDIDATE_STATUS.HR_ORIENTATION ? null : (
                <FormConstantDataSection
                  mrfNumber={String(
                    tagData?.ticketNumber ||
                      rowForUpdateTag?.ticket?.ticketNumber ||
                      '',
                  )}
                  job={
                    tagData?.jobTitle || rowForUpdateTag?.ticket?.jobTitle || ''
                  }
                  showEducation={status === CANDIDATE_STATUS.INTERVIEW_CLEARED}
                />
              )}
              <div className='w-full flex flex-col'>
                {status === CANDIDATE_STATUS.INTERVIEW_CLEARED ? (
                  <RecruiterInterviewEvaluationForm
                    control={interviewEvaluationControl}
                    error={interviewEvaluationError}
                    data={tagData || rowForUpdateTag}
                  />
                ) : null}
                {status === CANDIDATE_STATUS.HR_ORIENTATION ? (
                  <PersonnelActionForm
                    control={panControl}
                    watch={panWatch}
                    error={panError}
                    data={tagData || rowForUpdateTag}
                    resetField={resetPanField}
                  />
                ) : null}
                {status === CANDIDATE_STATUS.ENDORSED ? (
                  <EndorsementForm
                    data={tagData || rowForUpdateTag}
                    control={endorsementControl}
                    error={endorsementError}
                    fileName={watchEndorsmentData('document')}
                    progress={uploadProgress}
                  />
                ) : null}
                {isCompanyClientIAF ? (
                  <InterviewEvaluationHistory
                    candidate={editableCandidateData as ICandidate}
                    ticketId={(ticketValue as IDropdownOption)?.value}
                  />
                ) : null}
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </Modal>
  );
};

export default EditModal;

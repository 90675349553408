/* eslint-disable max-lines */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import toast from 'react-hot-toast';
import { IoSend } from 'react-icons/io5';
import { MdOutlineAttachFile } from 'react-icons/md';
import InfiniteScroll from 'react-infinite-scroll-component';

import { IPaginationResponse } from '@/@types';
import { IUser } from '@/@types/auth';
import { ICandidate } from '@/@types/candidate';
import {
  Attachment,
  ICreateBulkChatResponse,
  ICreateChatPayload,
  ICreateChatResponse,
  IMessage,
  IMessageNotification,
  IReceivedMessage,
} from '@/@types/chat';
import CircularLoader from '@/components/ClipLoader';
import Container from '@/components/Container';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Separator } from '@/components/ui/separator';
import { Textarea } from '@/components/ui/textarea';
import { FILE_SIZE_LIMIT, USER_TYPE } from '@/constants';
import { MESSAGE_TYPE } from '@/constants/chat';
import { useGenericMutation } from '@/hooks/useMutationData';
import { useGenericQuery } from '@/hooks/useQueryData';
import { cn } from '@/lib/utils';
import { strings } from '@/locales';
import { useAuth } from '@/provider/AuthProvider';
import { useChatContext } from '@/provider/ChatProvider';
import {
  createBulkChat,
  createChat,
  getChatById,
  readChatMessages,
} from '@/services/chat';
import {
  convertFilesToBuffers,
  joinConversation,
  leaveConversation,
  messageNotification,
  receiveMessage,
  sendMessage,
} from '@/utils/chat';
import {
  InfiniteData,
  useInfiniteQuery,
  useQueryClient,
} from '@tanstack/react-query';

import StartChattingAnimationView from '../StartChattingAnimationView';

import AttachmentPreview from './AttachmentPreview';
import ChatHeader from './ChatHeader';
import ChatLoadingState from './ChatLoadingState';
import Message from './Message';
import TemplateSection from './TemplateSection';

const ChatArea = () => {
  const { common, chatScreen: staticText } = strings;

  const queryClient = useQueryClient();
  const { user: loggedInUser } = useAuth();
  const containerRef = useRef<HTMLDivElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const { user } = useAuth();
  const {
    isBroadcastMessage,
    conversationId,
    activeChat,
    allChats,
    setChatList,
    chatList,
    selectedRecipients,
    setConversationId,
  } = useChatContext();

  const [message, setMessage] = useState('');
  const [isSending, setIsSending] = useState(false);
  const [attachments, setAttachments] = useState<Attachment[]>([]);
  const [broadcastMessages, setBroadcastMessages] = useState<IMessage[]>([]);
  const [broadcastConversations, setBroadcastConversations] = useState<
    ICreateChatResponse[]
  >([]);

  const resetSendingState = () => {
    setMessage('');
    setAttachments([]);
    setIsSending(false);
  };

  const createConversationMutation = useGenericMutation<
    ICreateChatPayload,
    boolean | ICreateChatResponse
  >(createChat, {
    onSuccess: (response) => {
      if (typeof response === 'object') {
        if (loggedInUser) {
          const recipient =
            (activeChat?.user as ICandidate)?.userDetails ||
            (activeChat?.user as IUser);
          joinConversation(response._id);
          const newMessage = {
            to: recipient._id!,
            from: loggedInUser._id!,
            conversationId: response._id,
            docs: attachments,
            message: message.trim(),
            messageType: MESSAGE_TYPE.USER_GENERATED,
          };
          sendMessage(newMessage);

          setTimeout(() => {
            queryClient.invalidateQueries({
              queryKey: ['chatList'],
            });
            resetSendingState();
            setConversationId(response._id);
          }, 500);
        }
      }
    },
  });

  const { data, isLoading, hasNextPage, fetchNextPage } = useInfiniteQuery({
    queryKey: ['getChatHistory', conversationId],
    queryFn: ({ pageParam = 1 }) =>
      getChatById(conversationId, pageParam.toString()),
    initialPageParam: 1,
    enabled: !!conversationId,
    getNextPageParam: (data, pages) => {
      if (typeof data === 'object' && pages.length < data.totalPages) {
        return pages.length + 1;
      }
      return undefined;
    },
  });

  const chatHistory = useMemo(() => {
    return (
      data?.pages
        .filter(Boolean)
        .flatMap((page) => (page as IPaginationResponse<IMessage>).results) ||
      []
    );
  }, [data]);

  useGenericQuery(
    ['readChatMessages', conversationId, chatHistory[0]],
    () => readChatMessages(conversationId, queryClient),
    {
      enabled:
        !isLoading &&
        !!chatHistory.length &&
        allChats.some(
          (chat) =>
            chat.conversationId === conversationId && chat.unreadCount > 0,
        ) &&
        chatHistory[0]?.from?._id !== loggedInUser?._id,
    },
  );

  const bulkChatMutation = useGenericMutation<
    { payload: ICreateChatPayload[] },
    ICreateBulkChatResponse | boolean
  >(({ payload }) => createBulkChat(payload), {
    onSuccess: (response) => {
      if (typeof response === 'object') {
        const broadcastList = [
          ...response.createdConversations,
          ...response.skippedConversations,
        ];
        setBroadcastConversations(broadcastList);
        if (loggedInUser) {
          broadcastList.forEach((conversation) => {
            joinConversation(conversation._id);

            sendMessage({
              to:
                conversation.user1 === loggedInUser._id!
                  ? conversation.user2
                  : conversation.user1,
              from: loggedInUser._id!,
              conversationId: conversation._id,
              docs: attachments,
              message: message.trim(),
              messageType: MESSAGE_TYPE.USER_GENERATED,
            });
            leaveConversation(conversation._id);
          });
          queryClient.invalidateQueries({
            queryKey: ['chatList'],
          });
          resetSendingState();
        }
      }
    },
  });

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const inputFilesArray = Array.from(e.target.files);

      if (inputFilesArray.some((file) => file.size > FILE_SIZE_LIMIT)) {
        toast.error(common.fileSizeError);
      }
      const newFiles = inputFilesArray.filter(
        (file) => file.size <= FILE_SIZE_LIMIT,
      );
      const bufferedAttachments = (await convertFilesToBuffers(
        newFiles,
      )) as Attachment[];
      setAttachments([...attachments, ...bufferedAttachments]);
    }
    e.target.value = '';
  };

  const handleSend = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      if ((message.trim() || attachments.length) && loggedInUser) {
        const recipient =
          (activeChat?.user as ICandidate)?.userDetails ||
          (activeChat?.user as IUser);

        const newMessage = {
          to: recipient,
          from: loggedInUser,
          conversationId,
          docs: attachments,
          message: message.trim(),
          messageType: MESSAGE_TYPE.USER_GENERATED,
          createdAt: new Date().toISOString(),
          seen: false,
        };
        if (isBroadcastMessage && broadcastMessages.length === 0) {
          const payload = selectedRecipients?.map((recipient) => ({
            user1: recipient.userDetails!._id!,
            user2: loggedInUser._id!,
          }));
          bulkChatMutation.mutateAsync({ payload });
          setBroadcastMessages((prev) => [newMessage, ...prev]);
        } else if (!conversationId && !isBroadcastMessage) {
          const payload = {
            user1: recipient._id!,
            user2: loggedInUser._id!,
            ticketId: activeChat?.ticket?._id,
          };
          createConversationMutation.mutateAsync(payload);
        } else {
          queryClient.setQueryData(
            ['getChatHistory', conversationId],
            (oldData: InfiniteData<IPaginationResponse<IMessage>>) => {
              if (!oldData?.pages) return oldData;

              const updatedPages = oldData.pages?.map((page, index: number) => {
                if (index === 0) {
                  return {
                    ...page,
                    results: [newMessage, ...page.results],
                  };
                }
                return page;
              });

              return {
                ...oldData,
                pages: updatedPages,
              };
            },
          );
          setIsSending(true);
        }
      }
    },
    [
      message,
      activeChat,
      conversationId,
      attachments,
      isBroadcastMessage,
      broadcastMessages,
      selectedRecipients,
      bulkChatMutation,
      loggedInUser,
      isSending,
    ],
  );

  const handleKeyPress = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSend(event);
    }
  };

  const handleRemoveAttachment = (index: number) => {
    setAttachments(attachments.filter((_, i) => i !== index));
  };

  const handleInput = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  const handleInActiveChatMessage = useMemo(() => {
    return (messageAlert: IMessageNotification) => {
      messageNotification(
        messageAlert,
        allChats,
        chatList,
        setChatList,
        queryClient,
      );
    };
  }, [allChats, chatList, setChatList, queryClient]);

  useEffect(() => {
    if (loggedInUser && isSending && (message.trim() || attachments.length)) {
      if (isBroadcastMessage && broadcastConversations.length) {
        const newMessage = {
          from: loggedInUser,
          message: message.trim(),
          docs: attachments,
          seen: false,
          messageType: MESSAGE_TYPE.USER_GENERATED,
          createdAt: new Date().toISOString(),
        };
        broadcastConversations.forEach((conversation) => {
          joinConversation(conversation._id);
          sendMessage({
            ...newMessage,
            to:
              conversation.user1 === loggedInUser._id!
                ? conversation.user2
                : conversation.user1,
            from: loggedInUser._id!,
            conversationId: conversation._id,
          });
          leaveConversation(conversation._id);
        });
        setBroadcastMessages((prev) => [newMessage, ...prev]);
        resetSendingState();
      } else {
        if (conversationId) {
          const recipient =
            (activeChat?.user as ICandidate)?.userDetails ||
            (activeChat?.user as IUser);
          sendMessage({
            to: recipient._id!,
            from: loggedInUser._id!,
            conversationId,
            docs: attachments,
            message: message.trim(),
            messageType: MESSAGE_TYPE.USER_GENERATED,
          });
          resetSendingState();
        }
      }
    }
  }, [isSending]);

  useEffect(() => {
    handleInput();
  }, [message]);

  useEffect(() => {
    receiveMessage((newMessage: IReceivedMessage) => {
      if (newMessage.conversationId === conversationId) {
        const sender =
          (activeChat?.user as ICandidate)?.userDetails ||
          (activeChat?.user as IUser);

        queryClient.setQueryData(
          ['getChatHistory', conversationId],
          (oldData: InfiniteData<IPaginationResponse<IMessage>>) => {
            if (!oldData?.pages) return oldData;

            const receivedMessage = {
              ...newMessage.message.data,
              from: sender,
            };
            const updatedPages = oldData.pages.map((page, index: number) => {
              if (index === 0) {
                // Check if the message already exists in the first page
                const messageExists = page.results.some(
                  (message: IMessage) => message._id === receivedMessage._id,
                );
                if (!messageExists) {
                  return {
                    ...page,
                    results: [receivedMessage, ...page.results],
                  };
                }
              }
              return page;
            });
            return {
              ...oldData,
              pages: updatedPages,
            };
          },
        );
      } else {
        handleInActiveChatMessage({
          conversationId: newMessage.conversationId,
        });
      }
    });
  }, [conversationId, handleInActiveChatMessage]);

  useEffect(() => {
    if (conversationId) {
      joinConversation(conversationId);
    }
  }, [conversationId]);

  useEffect(() => {
    if (isBroadcastMessage) {
      const handleBeforeUnload = (event: BeforeUnloadEvent) => {
        event.preventDefault();
      };

      window.addEventListener('beforeunload', handleBeforeUnload);

      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
    }
    return () => {};
  }, [isBroadcastMessage]);

  useEffect(() => {
    setBroadcastMessages([]);
  }, [selectedRecipients]);

  const Content = () => {
    if (isLoading) return <ChatLoadingState />;
    if (isBroadcastMessage && !broadcastMessages?.length)
      return <StartChattingAnimationView />;
    if (broadcastMessages?.length) {
      return (
        <div className='h-full overflow-y-auto flex flex-col-reverse gap-4'>
          {broadcastMessages?.map((item, index) => (
            <Message
              key={`Message-${index}`}
              message={item}
              showAvatar={
                broadcastMessages[index - 1]?.from?._id !== item?.from?._id
              }
            />
          ))}
        </div>
      );
    }
    if (!chatHistory.length)
      return <StartChattingAnimationView isEmpty={!chatHistory.length} />;

    return (
      <div
        ref={containerRef}
        id='scrollableDiv'
        className='h-full overflow-y-auto flex flex-col-reverse'
      >
        <InfiniteScroll
          dataLength={chatHistory.length}
          next={fetchNextPage}
          hasMore={hasNextPage}
          loader={<CircularLoader />}
          scrollableTarget='scrollableDiv'
          inverse={true}
          initialScrollY={containerRef.current?.scrollHeight}
          className='flex gap-4 flex-col-reverse'
        >
          {chatHistory?.map((item, index) => (
            <Message
              key={`Message-${index}`}
              message={item}
              showAvatar={chatHistory[index - 1]?.from?._id !== item?.from?._id}
            />
          ))}
        </InfiniteScroll>
      </div>
    );
  };

  return (
    <Container
      width='w-full lg:w-3/4'
      height='h-[96%] lg:h-full'
      className='flex flex-col gap-4'
    >
      <ChatHeader />
      <Separator />
      {Content()}
      <form onSubmit={handleSend}>
        <div
          className={cn('flex w-full justify-between items-center gap-x-2', {
            'items-end': attachments.length,
          })}
        >
          <div className='flex bg-simplyViolet rounded-xl w-[92%] xl:w-[95%] justify-between items-end'>
            <div className='flex flex-col w-[calc(100%-65px)] xl:w-[calc(100%-65px)]'>
              <Textarea
                rows={1}
                ref={textareaRef}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder={staticText.typeYourMessage}
                onKeyDown={handleKeyPress}
                className='bg-simplyViolet resize-none border-none w-full placeholder:text-quickSilver text-sm md:text-base font-medium min-h-10 text-primaryBlack max-h-40'
              />
              {attachments?.length ? (
                <div className='flex items-center overflow-x-scroll h-20 gap-3 pl-3'>
                  {attachments?.map((attachment, index) => (
                    <AttachmentPreview
                      key={`attachment-${attachment.originalname}`}
                      attachment={attachment}
                      onRemoveAttachment={() => handleRemoveAttachment(index)}
                    />
                  ))}
                </div>
              ) : null}
            </div>
            <div
              className={cn('flex shrink-0 h-10 mr-1 gap-2 ', {
                'mb-5 sm:mb-2': attachments.length,
              })}
            >
              {user?.profileType === USER_TYPE.RECRUITER ||
              user?.profileType === USER_TYPE.ADMIN ? (
                <TemplateSection setMessage={setMessage} />
              ) : null}
              <Input
                id='fileInput'
                type='file'
                onChange={handleFileChange}
                multiple
                accept='image/*,.docx,.pdf'
                className='hidden'
              />
              <label
                htmlFor='fileInput'
                className='bg-simplyViolet h-full rounded-r-xl flex items-center justify-center cursor-pointer'
              >
                <MdOutlineAttachFile className='text-xl sm:text-2xl rotate-45 text-mouseGrey' />
              </label>
            </div>
          </div>
          <Button
            disabled={
              (!message.trim().length && !attachments.length) ||
              isLoading ||
              isSending
            }
            size='icon'
            icon={<IoSend className='-rotate-45 text-[8px] sm:text-base' />}
            className={cn(
              'rounded-full flex items-center justify-center size-5 sm:size-9',
              {
                'mb-8 sm:mb-2': attachments.length,
              },
            )}
          />
        </div>
      </form>
    </Container>
  );
};

export default ChatArea;

/* eslint-disable max-lines */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import FileViewer from 'react-file-viewer';
import { SubmitHandler, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { BiChat } from 'react-icons/bi';
import { FaCheckCircle } from 'react-icons/fa';
import { FiEdit } from 'react-icons/fi';
import { HiMiniArrowTopRightOnSquare } from 'react-icons/hi2';
import { IoMdCloseCircle } from 'react-icons/io';
import { useNavigate, useParams } from 'react-router-dom';

import { ICandidate, NotesType, UpdatedNotes } from '@/@types/candidate';
import BackHeader from '@/components/BackHeader';
import FileDropContainer from '@/components/FileDropContainer';
import StateIndicator from '@/components/StateIndicator';
import { Typography } from '@/components/Typography';
import { Button } from '@/components/ui/button';
import { AcceptedFileFormats } from '@/constants';
import { useGenericMutation } from '@/hooks/useMutationData';
import { useGenericQuery } from '@/hooks/useQueryData';
import { cn } from '@/lib/utils';
import { strings } from '@/locales';
import { useAuth } from '@/provider/AuthProvider';
import { useCandidate } from '@/provider/CandidateProvider';
import { useChatContext } from '@/provider/ChatProvider';
import { useSidebarContext } from '@/provider/SidebarProvider';
import { ROUTES } from '@/routes';
import {
  fetchCandidateDetail,
  updateNotesDetail,
  uploadResume,
} from '@/services/candidate';
import { removeSegmentsFromUrl } from '@/utils/common';

import EditModal from '../Candidate/components/EditModal';
import ProfileStatus from '../Dashboard/components/ProfileStatus';

import CandidateInfo from './components/CandidateInfo';
import Documents from './components/Documents';

export interface FileDropContainerRef {
  clearFileUpload: () => void;
}

const CandidateDetail = () => {
  const staticText = strings.candidateDetails;
  const fileDropRef = useRef<FileDropContainerRef>(null);

  const { hasSubRole, isRecruiter, isAdmin } = useAuth();
  const navigate = useNavigate();
  const { setActiveChat } = useChatContext();
  const { id } = useParams<{ id: string }>();
  const {
    fromTicketScreen,
    setFromTicketScreen,
    fromApprovalScreen,
    setFromApprovalScreen,
  } = useSidebarContext();

  const {
    editableCandidateData,
    setEditableCandidateData,
    setOpenEditModal,
    openEditModal,
  } = useCandidate();

  const [isEdit, setIsEdit] = useState(false);
  const [resume, setResume] = useState<File | null>(null);
  const [resumeProgress, setResumeProgress] = useState(0);
  const [candidateData, setCandidateData] = useState<ICandidate | null>(null);
  const divRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState<number | null>(null);

  const { data, isLoading, refetch } = useGenericQuery(
    ['candidate-detail', id],
    () => fetchCandidateDetail(id!),
  );

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useForm<NotesType>({
    mode: 'all',
    defaultValues: {
      notes: (candidateData as ICandidate)?.notes || '',
    },
  });

  const updateNotesMutation = useGenericMutation<
    { id: string; notes: UpdatedNotes },
    UpdatedNotes | boolean
  >(({ id, notes }) => updateNotesDetail(id, notes), {
    onSuccess: (response) => {
      if (response) {
        refetch();
        toast.success(staticText.notesSuccess);
        setIsEdit(false);
      }
    },
  });

  const onSubmit: SubmitHandler<NotesType> = (data) => {
    if (!id) return;
    const payload = {
      notes: data.notes,
    };
    updateNotesMutation.mutate({ id, notes: payload });
  };

  const handleResumeProgress = useCallback((percentage: number) => {
    setResumeProgress(percentage);
  }, []);

  const uploadResumeMutation = useGenericMutation<
    { resume: File; candidate: string } & {
      setResumeProgress?: (progress: number) => void;
    },
    ICandidate | boolean
  >(uploadResume, {
    onSuccess: () => {
      refetch();
      setResumeProgress(0);
      fileDropRef.current?.clearFileUpload();
    },
  });

  const handleSaveOrChatButton = () => {
    if (resume) {
      setResumeProgress(0);
      uploadResumeMutation.mutate({
        resume: resume as File,
        candidate: candidateData?._id || '',
        setResumeProgress: handleResumeProgress,
      });
    }
    if (isEdit) {
      handleSubmit(onSubmit)();
    } else if (candidateData && !resume) {
      navigate(ROUTES.CHAT);
      setActiveChat({ user: candidateData, ticket: null });
    }
  };

  const handleEditOrCancelButton = () => {
    if (resume) {
      setResume(null);
      fileDropRef.current?.clearFileUpload();
    } else {
      if (isEdit) {
        setIsEdit(false);
        reset({ notes: (data as ICandidate).notes });
      } else {
        setIsEdit(true);
      }
    }
  };

  const handleEditTagModalOpen = () => {
    if (candidateData) {
      setEditableCandidateData(candidateData);
      setOpenEditModal(!openEditModal);
    }
  };

  useEffect(() => {
    if (typeof data === 'object') {
      setCandidateData(data);
      reset({ notes: (data as ICandidate).notes });
    }
  }, [data]);

  const handleBackButton = () => {
    if (fromTicketScreen) {
      navigate(fromTicketScreen);
      setFromTicketScreen('');
    } else if (fromApprovalScreen) {
      navigate(fromApprovalScreen);
      setFromApprovalScreen('');
    } else {
      removeSegmentsFromUrl(navigate, location.pathname);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      if (divRef.current) {
        setHeight(divRef.current.offsetHeight);
      }
    };
    const resizeObserver = new ResizeObserver(handleResize);
    if (divRef.current) {
      resizeObserver.observe(divRef.current);
    }
    return () => {
      if (resizeObserver) {
        resizeObserver.disconnect();
      }
    };
  }, []);

  if (!isLoading && !data) return <StateIndicator state='Error' />;
  return (
    <>
      <div className='flex flex-col sm:flex-row gap-5 sm:gap-0 justify-between mb-5'>
        <BackHeader title={staticText.title} handleBackBtn={handleBackButton} />
        {!hasSubRole ? (
          <div className='flex gap-3 justify-end sm:justify-normal'>
            <Button
              variant='outline'
              className={cn(
                'rounded-md sm:rounded-xl w-32 h-9 md:px-auto lg:h-11 text-base',
                { 'px-2 text-mouseGrey border-mouseGrey': isEdit || resume },
              )}
              icon={
                isEdit || resume ? (
                  <IoMdCloseCircle size={20} />
                ) : (
                  <FiEdit size={20} />
                )
              }
              onClick={handleEditOrCancelButton}
              disabled={
                updateNotesMutation.isPending || uploadResumeMutation.isPending
              }
            >
              {isEdit || resume ? strings.cancel : staticText.edit}
            </Button>
            <Button
              variant='outline'
              className='rounded-md sm:rounded-xl w-32 h-9 md:px-auto lg:h-11 text-base'
              icon={
                isEdit || resume ? (
                  <FaCheckCircle size={16} />
                ) : (
                  <BiChat size={21} />
                )
              }
              onClick={handleSaveOrChatButton}
              loading={
                uploadResumeMutation.isPending || updateNotesMutation.isPending
              }
              disabled={
                (isEdit ? !isDirty || updateNotesMutation.isPending : false) ||
                uploadResumeMutation.isPending
              }
            >
              {isEdit || resume ? strings.save : staticText.chat}
            </Button>
          </div>
        ) : null}
      </div>
      {candidateData?.tags?.length ? (
        <ProfileStatus tags={candidateData?.tags} />
      ) : null}

      <div className='flex flex-col gap-6'>
        <div className='lg:grid grid-cols-5 gap-7 space-y-7 lg:space-y-0'>
          <div ref={divRef} className='col-span-3'>
            <CandidateInfo
              data={candidateData!}
              isEdit={isEdit}
              control={control}
              isLoading={isLoading}
              handleEditTagModalOpen={handleEditTagModalOpen}
            />
          </div>

          <div
            className={cn(
              'col-span-2 w-full relative rounded-xl border pt-4 bg-white border-greyWhite scrollbarHidden',
              {
                'overflow-scroll': height,
              },
            )}
            style={{
              height:
                height && window.innerWidth > 1024 ? `${height}px` : 'auto',
            }}
          >
            {candidateData?.resume ? (
              <a
                href={candidateData?.resume}
                download
                target='_blank'
                rel='noreferrer'
                className='justify-end right-1.5 top-1.5 absolute z-20'
              >
                <Button
                  variant='ghost'
                  size='icon'
                  icon={<HiMiniArrowTopRightOnSquare className='text-lg' />}
                  className='bg-white size-8 rounded-sm'
                />
              </a>
            ) : null}
            {isLoading ? (
              <div className='h-[500px]'>
                <StateIndicator state='Loading' />
              </div>
            ) : (
              <>
                {candidateData?.resume ? (
                  <FileViewer
                    fileType={candidateData?.resume?.split('.').pop() || 'pdf'}
                    filePath={candidateData?.resume}
                  />
                ) : null}
                {!candidateData?.resume && !isRecruiter && !isAdmin ? (
                  <StateIndicator state='Empty' />
                ) : null}
                {!candidateData?.resume && (isRecruiter || isAdmin) ? (
                  <div className='p-3 '>
                    <Typography className='text-base md:text-lg font-semibold'>
                      {staticText.uploadResume}
                    </Typography>
                    <FileDropContainer
                      buttonText='Upload Resume'
                      setFile={setResume}
                      fileName={resume ? resume.name : ''}
                      dropzoneClassName='w-full h-28 p-2 mt-3'
                      uploadingDivClassName='w-full h-28'
                      iconClassName='bg-primary rounded-full text-white text-[40px] p-2'
                      acceptedFileFormat={AcceptedFileFormats}
                      disabled={uploadResumeMutation.isPending || isEdit}
                      ref={fileDropRef}
                      progress={resume ? 0 : resumeProgress}
                    />
                  </div>
                ) : null}
              </>
            )}
          </div>
        </div>
        {isRecruiter || (isAdmin && !hasSubRole) ? (
          <Documents candidateId={id} />
        ) : null}
      </div>
      {openEditModal && editableCandidateData ? (
        <EditModal refetch={refetch} />
      ) : null}
    </>
  );
};

export default CandidateDetail;

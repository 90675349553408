import React from 'react';

import { Typography } from '@/components/Typography';
import { DONUT_COLORS, getLabelFromName } from '@/constants/dashboard';
import { cn } from '@/lib/utils';

export interface ISource {
  name: string;
  count: number;
  percentage?: string;
}

interface Props {
  data?: ISource[];
  cardDisplay: boolean;
  colors?: string[];
  showPercentage?: boolean;
  dashboardCards?: boolean;
  isRecruitersDashboardKey?: boolean;
}

const SourcesKeyList: React.FC<Props> = ({
  data,
  cardDisplay,
  colors,
  showPercentage = true,
  dashboardCards,
  isRecruitersDashboardKey,
}) => {
  const dataWithPercentages = () => {
    const totalCount = data?.reduce((acc, item) => acc + item.count, 0) || 0;

    return data?.map((item) => ({
      ...item,
      percentage: ((item.count / totalCount) * 100).toFixed(2),
    }));
  };

  return (
    <div
      className={cn('flex flex-col gap-3 my-2', {
        'gap-0.5': cardDisplay,
        'my-0 gap-1.5': dashboardCards,
      })}
    >
      {dataWithPercentages()?.map((item, index) => {
        return (
          <div
            key={index}
            className={cn('flex items-center gap-4 ', {
              'gap-1': cardDisplay,
              'gap-1.5': dashboardCards,
            })}
          >
            <div
              className={cn('size-3 rounded-full', {
                'size-1.5': cardDisplay,
                'size-3': dashboardCards,
              })}
              style={{
                background: isRecruitersDashboardKey
                  ? `linear-gradient(135deg, #000 0%, ${colors?.[index]} 100%`
                  : `linear-gradient(135deg, ${colors ? `${colors[index]}95` : ''} 0%, ${colors ? colors[index] : ''} 100%, ${`${DONUT_COLORS[index]}95`} 0%, ${DONUT_COLORS[index]} 100%)`,
              }}
            />
            <div className='flex space-between items-center'>
              <Typography
                className={cn(
                  'md:text-base font-medium text-mouseGrey text-ellipsis w-40',
                  {
                    'text-[7px] md:text-[7px] w-16': cardDisplay,
                    'w-28 text-xs md:text-sm mr-1': dashboardCards,
                  },
                )}
              >
                {getLabelFromName(item.name)}
              </Typography>
              <Typography
                className={cn('md:text-base font-bold text-left', {
                  'text-[7px] md:text-[7px]': cardDisplay,
                  'text-xs md:text-sm font-semibold': dashboardCards,
                })}
              >
                {`${item.count.toFixed()}${showPercentage ? '%' : ''}`}
              </Typography>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default SourcesKeyList;

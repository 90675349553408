import React from 'react';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';

import { PROGRESS_BAR_COLORS } from '@/constants/dashboard';
import { strings } from '@/locales';
import SourcesKeyList from '@/pages/Dashboard/components/SourcesKeyList';
import { useAuth } from '@/provider/AuthProvider';

import { Skeleton } from './ui/skeleton';
import { Typography } from './Typography';

import 'react-circular-progressbar/dist/styles.css';

interface Props {
  isLoading: boolean;
  data: number;
  total: number;
}

const CircularProgressBar: React.FC<Props> = ({
  data,
  total,
  isLoading = true,
}) => {
  const staticText = strings.dashboard;
  const { isRecruitmentManager } = useAuth();

  const formattedData = [
    {
      name: `${
        isRecruitmentManager ? staticText.totalHired : staticText.closedTickets
      }:`,
      count: data,
    },
    {
      name: `${
        isRecruitmentManager
          ? staticText.totalHeadcount
          : staticText.totalTickets
      }:`,
      count: total,
    },
  ];

  const LoadingSkeleton = ({ noPulsing }: { noPulsing?: boolean }) => {
    return (
      <div className='flex w-full justify-between items-center gap-1 px-5'>
        <Skeleton
          noPulsing={noPulsing}
          className='size-20 rounded-full flex items-center justify-center'
        >
          <div className='size-16 bg-trafficWhite rounded-full' />
        </Skeleton>
      </div>
    );
  };

  if (isLoading) return <LoadingSkeleton />;

  return (
    <div className='flex w-full items-center gap-5 px-5'>
      <div className='size-[90px] flex justify-center items-center'>
        <CircularProgressbarWithChildren
          value={total ? (data / total) * 100 : 0}
          strokeWidth={10}
          styles={{ path: { stroke: '#2B8ABC' } }}
        >
          <Typography className='text-xs md:text-xs font-bold text-center m-20'>
            {`${total ? Math.round((data / total) * 100) : '0'}% `}
            {isRecruitmentManager ? '' : staticText.hiringDone}
          </Typography>
        </CircularProgressbarWithChildren>
      </div>
      <div className='w-fit'>
        <SourcesKeyList
          data={formattedData}
          cardDisplay={false}
          colors={PROGRESS_BAR_COLORS}
          showPercentage={false}
          dashboardCards
        />
      </div>
    </div>
  );
};

export default CircularProgressBar;

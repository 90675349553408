import React, { useEffect, useState } from 'react';

import { ITagType } from '@/@types/candidate';
import Container from '@/components/Container';
import { Typography } from '@/components/Typography';
import { cn } from '@/lib/utils';
import { strings } from '@/locales';

import Banner from '../../../assets/images/banner.webp';

interface IProfileStatus {
  tags?: ITagType[];
}

const ProfileStatus: React.FC<IProfileStatus> = ({ tags }) => {
  const staticText = strings.dashboard;

  const [fade, setFade] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const list =
    tags?.map((item) => ({
      jobTitle: item.jobPosition,
      status: item.status,
    })) || [];

  useEffect(() => {
    const interval = setInterval(() => {
      setFade(true); // Trigger fade-out before updating
      setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % list.length);
        setFade(false); // Reset fade to trigger fade-in
      }, 500); // Wait for the fade-out to finish (500ms)
    }, 5000);
    return () => clearInterval(interval); // Clean up on unmount
  }, []);

  return (
    <Container className='h-fit relative k overflow-hidden md:px-4 md:py-3 bg-black bg-opacity-50 mb-7'>
      <div className='absolute top-0 left-0 w-full h-full bg-blue-500 opacity-80 z-10 bg-gradient-to-r from-blueMoon via-hydra to-bracingBlue' />
      <img className='absolute top-0 left-0 w-full h-full ' src={Banner} />
      <div className='relative z-20 flex justify-center flex-col items-center'>
        <Typography className='text-white text-lg text-center md:text-2xl block font-bold'>
          {staticText.message}
        </Typography>
      </div>
      <div className='text-center relative z-20'>
        <Typography
          className={cn(
            'text-lg md:text-xl text-primaryGreen block font-bold transition-opacity duration-500',
            {
              'opacity-0': fade,
              'opacity-100': !fade,
            },
          )}
        >
          {list[currentIndex]?.jobTitle}
        </Typography>
        <Typography
          className={cn(
            'text-white text-sm md:text-base block font-bold transition-opacity duration-500',
            {
              'opacity-0': fade,
              'opacity-100': !fade,
            },
          )}
        >
          <span className='text-white'>{staticText.jobStatus}: </span>
          <span className=' text-primaryGreen '>
            {list[currentIndex]?.status}
          </span>
        </Typography>
      </div>
    </Container>
  );
};

export default ProfileStatus;

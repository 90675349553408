export enum MESSAGE_TYPE {
  SYSTEM_GENERATED = 'systemGenerated',
  USER_GENERATED = 'userGenerated',
}

export enum CHAT_SOCKET_EVENTS {
  SEND_MESSAGE = 'sendMessage',
  RECIEVE_MESSAGE = 'receiveMessage',
  JOIN_CONVERSATION = 'joinConversation',
  LEAVE_CONVERSATION = 'leaveConversation',
  MESSAGE_NOTIFICATION = 'messageNotification',
}

export const urlRegex = /(https?:\/\/[^\s]+)/g;

export enum WELCOME_MESSAGE_TYPE {
  WELCOME_RECRUITER_MESSAGE = 'recruiterWelcomeMessage',
  WELCOME_CANDIDATE_MESSAGE = 'candidateWelcomeMessage',
  WELCOME_COMPANY_CLIENT_MESSAGE = 'companyClientWelcomeMessage',
}
